/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import clienteContext from "../../context/cliente/clienteContext";
import AlertaContext from "../../context/alerta/alertaContext";
import AuthContext from "../../context/autenticacion/authContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import styled from "styled-components";
import Button from "components/CustomButtons/Button.js";
import DataTable from "react-data-table-component";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdPeopleOutline } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import MaterialTable from "material-table";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: "red",
  },
}));

const ListadoCliente = () => {
  const classes = useStyles();

  const [tc, setTC] = useState(false);
  const [aviso, setAviso] = useState("");

  //Extraer clientes del state inicial
  const clientesContext = useContext(clienteContext);
  const {
    mensaje,
    clientes,
    obtenerClientes,
    agregarCliente,
    actualizarCliente,
    clienteActual,
    eliminarCliente,
    guardarClienteActual,
    clienteseleccionado,
  } = clientesContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  
  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado } = authContext;

  //Obtener clientes cuando carga el componente
  useEffect(() => {
    //Si hay un error
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      // console.log(mensaje.msg);
      // setTC(true);
      // setAviso(mensaje.msg)
      return
    }
    obtenerClientes();

    // eslint-disable-next-line
  }, [mensaje]);
  
 console.log(clientes)
 
  return (
    <>

{!usuario ? null : (
        <p
          style={{
            fontSize: "25px",
          }}
        >
          <strong>Bienvenido:</strong> {usuario.nombre}
        </p>
      )}
      {/* Revisar si clientes tiene contenido */}
      {clientes.length === 0 ? (
        <Alert severity="error">
          <AlertTitle>Advertencia</AlertTitle>
          <strong>No hay clientes</strong>, comienza creando uno
        </Alert>
      ) : null}



      {/* {alerta ? (
        <SnackbarContent message={alerta.msg} color={alerta.categoria} close />
      ) : null} */}

      <Snackbar
        place="tc"
        color="danger"
        icon={AddAlert}
        message={aviso}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />

      <MaterialTable
        icons={{
          Edit: () => <EditOutlinedIcon color="primary" />,
          Delete: () => <DeleteOutlineOutlinedIcon color="secondary" />,
          Add: () => (
            <AddCircleOutlineIcon
              fontSize="large"
              style={{ color: green[500] }}
            />
          ),
        }}
        title="Listado de Clientes"
        columns={[
          {
            title: "Cedula",
            field: "cedula",
            editComponent: (props) => (
              <TextField
                variant="outlined"
                type="number"
                inputProps={{ min: "0", step: "1" }}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
          {
            title: "Nombre Completo",
            field: "nombre_completo",
            editComponent: (props) => (
              <TextField
                variant="outlined"
                type="text"
                inputProps={{ min: "0", step: "1" }}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
          {
            title: "Correo",
            field: "correo",
            editComponent: (props) => (
              <TextField
                variant="outlined"
                type="email"
                inputProps={{ min: "0", step: "1" }}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },

          {
            title: "Telefono",
            field: "telefono",
            editComponent: (props) => (
              <TextField
                variant="outlined"
                type="number"
                inputProps={{ min: "0", step: "1" }}
                inputProps={{ min: "0", step: "1" }}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
          {
            title: "Direccion",
            field: "direccion",
            editComponent: (props) => (
              <TextField
                variant="outlined"
                type="text"
                inputProps={{ min: "0", step: "1" }}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
        ]}
        data={clientes}
        options={{
          search: true,
          actionsColumnIndex: -1,
          // filtering: true,
          // exportButton: true,
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsSelect: "Filas",
            labelRowsPerPage: "Filas por pagina:",
            firstAriaLabel: "Primera Pagina",
            firstTooltip: "Primera Pagina",
            previousAriaLabel: "Pagina Anterior",
            previousTooltip: "Pagina Anterior",
            nextAriaLabel: "Siguiente Pagina",
            nextTooltip: "Siguiente Pagina",
            lastAriaLabel: "Ultima Pagina",
            lastTooltip: "Ultima Pagina",
          },
          toolbar: {
            nRowsSelected: "{0} z(s) selected",
            searchPlaceholder: "Busqueda",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            emptyDataSourceMessage: "Sin registros",
            addTooltip: "Agregar",
            editTooltip: "Editar",
            deleteTooltip: "Eliminar",

            editRow: {
              deleteText: "¿Está usted seguro de eliminar este cliente?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                var validar_correo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                  newData.correo
                );

               // console.log(clientes);
            

                if (
                  !newData.nombre_completo ||
                  !newData.cedula ||
                  !newData.telefono ||
                  !newData.direccion ||
                  !newData.correo
                ) {
                  setTC(true);
                  setAviso("Todos los campos deben ser diligenciados");
                  reject();
                  return;
                }

                if (validar_correo === false) {
                  setTC(true);
                  setAviso("Digite un correo valido");
                  reject();
                  return;
                }
                
            

                for(var i= 0; i <= clientes.length - 1; i++){
                  if(clientes[i].cedula === parseInt(newData.cedula)){
                  setTC(true);
                  setAviso("Número de cedula ya existe");
                  reject();
                  return 
                 }
                }

                for(var i= 0; i <= clientes.length - 1; i++){
                  if(clientes[i].correo === newData.correo){
                  setTC(true);
                  setAviso("El correo ingresado ya existe");
                  reject();
                  return 
                 }
                }
              

                resolve();
                setTC(false);

                // agregarCliente(newData);
                const data = [...clientes];
                data.push(newData);
                console.log(newData);
                agregarCliente(newData);
              }, 1000);
            }),

          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
               
                var validar_correo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                  newData.correo
                );
                  
                if (
                  !newData.nombre_completo ||
                  !newData.cedula ||
                  !newData.telefono ||
                  !newData.direccion ||
                  !newData.correo
                ) {
                  setTC(true);
                  setAviso("Todos los campos deben ser diligenciados");
                  reject();
                  return;
                }

                if (validar_correo === false) {
                  setTC(true);
                  setAviso("Digite un correo valido");
                  reject();
                  return;
                }
                
                if (parseInt(oldData.cedula) !== parseInt(newData.cedula) ){
                  for(var i= 0; i <= clientes.length - 1; i++){
                    if(clientes[i].cedula === parseInt(newData.cedula)){
                    setTC(true);
                    setAviso("Número de cedula ya existe");
                    reject();
                    return;
                   }
                  }                 
                }
                

                // for(var i= 0; i <= clientes.length - 1; i++){
                //   if(clientes[i].correo === newData.correo){
                //   setTC(true);
                //   setAviso("El correo ingresado ya existe");
                //   reject();
                //   return 
                //  }
                // }

               
                setTC(false);
                const dataUpdate = [...clientes];
                const index = oldData.tableData._id;
                dataUpdate[index] = newData;
                console.log(dataUpdate.undefined);
                actualizarCliente(dataUpdate.undefined);
                Swal.fire("Cliente Actualizado", "", "success");
                resolve();
              }, 1000);
            }),

          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...clientes];
                const index = oldData.tableData.id;
                const remove = dataDelete[index];
                //dataDelete.splice(index, 1);
                eliminarCliente(remove._id);
                resolve();
              }, 1000);
            }),
        }}
      />
    </>
  );
};

export default ListadoCliente;
