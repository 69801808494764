import React, { useState, useEffect, useContext } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/VerticalTimeline.css";
import "../../assets/css/VerticalTimelineElement.css";
import "../../assets/css/main.css";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import CuotaContext from "../../context/cuota/cuotaContext";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";
import Moment from "react-moment";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    paddingBottom: "1rem",
    background: "#212c400d",
  },
  texto: {
    textAlign: "center",
    fontWeight: "bold",
  },
  parrafo: {
    fontWeight: "bold",
    paddingTop: "0rem",
  },
}));

const InsideTimeLineCliente = ({ estados }) => {
  const classes = useStyles();
  const history = useHistory();
  //Context de prestamos
  const prestamosContext = useContext(PrestamoContext);
  const {
    prestamo,
    resultado,
    prestamoseleccionado,
    actualizarEstadoPrestamo,
    guardarResultado,
  } = prestamosContext;

  const CuotasContext = useContext(CuotaContext);
  const {
    obtenerCuotas,
    cuotasPrestamo,
    eliminarCuota,
    guardarCuotaActual,
  } = CuotasContext;

  useEffect(() => {
    if (!prestamoseleccionado) {
      history.push("/admin/prestamo");
      return;
    }
    obtenerCuotas(prestamoseleccionado._id);
  }, [prestamoseleccionado]);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      key={estados._id}
      contentArrowStyle={{
        borderRight: "10px solid  rgb(33, 150, 243)",
      }}
      date={<Moment format="DD/MM/YYYY">{estados.fecha_actuacion}</Moment>}
      iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
      icon={<WorkIcon />}
    >
      <h4 className={classes.parrafo}>{estados.actuacion}</h4>
      <p>{estados.anotacion}</p>
    </VerticalTimelineElement>
  );
};

export default InsideTimeLineCliente;
