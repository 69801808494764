import React, { useReducer } from "react";
import prestamoContext from "./prestamoContext";
import prestamoReducer from "./prestamoReducer";
import {
  AGREGAR_PRESTAMO,
  VALIDAR_PRESTAMO,
  OBTENER_PRESTAMOS,
  PRESTAMO_ERROR,
  ELIMINAR_PRESTAMO,
  ACTUALIZAR_PRESTAMO,
  PRESTAMO_ACTUAL,
  OBTENER_PRESTAMOS_CUOTAS,
  ACTUALIZAR_PRESTAMO_ESTADO,
  RESULTADOS,
  OBTENER_PROCESO_CLIENTE,
} from "../../types";

import clienteAxios from "../../config/axios";

const PrestamoState = (props) => {
  const initialState = {
    prestamos: [],
    procesoCliente: [],
    prestamoscuotas: [],
    resultado: [],
    errorformulario: false,
    mensaje: null,
    prestamo: null,
    prestamoseleccionado: null,
  };

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(prestamoReducer, initialState);

  //Serie de funciones para el crud
  //Obtener los prestamos
  const obtenerPrestamos = async () => {
    try {
      const resultado = await clienteAxios.get("/api/procesos");
      console.log(resultado);
      dispatch({
        type: OBTENER_PRESTAMOS,
        payload: resultado.data.procesos,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  const obtenerProcesosClientes = async () => {
    try {
      const resultado = await clienteAxios.get("/api/procesoCliente");
      console.log(resultado);
      dispatch({
        type: OBTENER_PROCESO_CLIENTE,
        payload: resultado.data.proceso,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Agregar nuevo prestamo
  const agregarPrestamo = async (prestamo) => {
    console.log(prestamo);
    try {
      const resultado = await clienteAxios.post("/api/procesos", prestamo);
      console.log(resultado);

      //Insertar prestamo en el state
      dispatch({
        type: AGREGAR_PRESTAMO,
        payload: resultado.data,
      });
    } catch (error) {
      console.log(error.response.data);
      const alerta = {
        msg: error.response.data.msg,
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Funcion para mostrar error cuando este vacio el prestamo  y se de submit
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_PRESTAMO,
    });
  };

  //Elimina un prestamo
  const eliminarPrestamo = async (prestamoId) => {
    try {
      await clienteAxios.delete(`/api/procesos/${prestamoId}`);
      dispatch({
        type: ELIMINAR_PRESTAMO,
        payload: prestamoId,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };

  //Selecciona el prestamo cuando el usuario le dio click
  const prestamoActual = (prestamoID) => {
    dispatch({
      type: PRESTAMO_ACTUAL,
      payload: prestamoID,
    });
  };

  //Actualizar y/o editar el prestamo seleccionado
  const actualizarPrestamo = async (prestamo) => {
    try {
      const resultado = await clienteAxios.put(
        `/api/procesos/${prestamo._id}`,
        prestamo
      );
      console.log(resultado);
      dispatch({
        type: ACTUALIZAR_PRESTAMO,
        payload: resultado.data.proceso,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Extraer un prestamo para edicion
  const guardarPrestamoActual = (prestamo) => {
    //console.log(prestamo)
    dispatch({
      type: PRESTAMO_ACTUAL,
      payload: prestamo,
    });
  };

  //Obtener los prestamos para las cuotas
  //   const obtenerPrestamosCuotas = async () => {
  //     try {
  //       const resultado = await clienteAxios.get("/api/procesos");
  //       console.log(resultado);
  //       dispatch({
  //         type: OBTENER_PRESTAMOS_CUOTAS,
  //         payload: resultado.data.prestamos,
  //       });
  //     } catch (error) {
  //       const alerta = {
  //         msg: "Hubo un error",
  //         categoria: "danger",
  //       };
  //       dispatch({
  //         type: PRESTAMO_ERROR,
  //         payload: alerta,
  //       });
  //     }
  //   };

  //Actualizar y/o editar el estado del prestamo seleccionado
  const actualizarEstadoPrestamo = async (prestamo) => {
    try {
      const resultado = await clienteAxios.put(
        `/api/prestamocuota/${prestamo._id}`,
        prestamo
      );
      console.log(resultado);
      dispatch({
        type: ACTUALIZAR_PRESTAMO_ESTADO,
        payload: resultado.data.prestamo,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Guardar Resultados de las cuotas
  const guardarResultado = (resultado) => {
    //     console.log(resultado)
    dispatch({
      type: RESULTADOS,
      payload: resultado,
    });
  };

  return (
    <prestamoContext.Provider
      value={{
        errorformulario: state.errorformulario,
        mensaje: state.mensaje,
        prestamos: state.prestamos,
        prestamoseleccionado: state.prestamoseleccionado,
        prestamoscuotas: state.prestamoscuotas,
        prestamo: state.prestamo,
        resultado: state.resultado,
        procesoCliente: state.procesoCliente,
        obtenerPrestamos,
        agregarPrestamo,
        mostrarError,
        eliminarPrestamo,
        prestamoActual,
        actualizarPrestamo,
        guardarPrestamoActual,
        // obtenerPrestamosCuotas,
        actualizarEstadoPrestamo,
        guardarResultado,
        obtenerProcesosClientes,
      }}
    >
      {props.children}
    </prestamoContext.Provider>
  );
};

export default PrestamoState;
