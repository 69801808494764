import React, { useReducer } from "react";
import CuotaContext from "./cuotaContext";
import CuotaReducer from "./cuotaReducer";

import {
  CUOTAS_PRESTAMO,
  AGREGAR_CUOTA,
  VALIDAR_CUOTA,
  ELIMINAR_CUOTA,
  ACTUALIZAR_CUOTA,
  CUOTA_ACTUAL,
  CUOTAS_TOTALES,
} from "../../types/index";

import clienteAxios from "../../config/axios";

const CuotaState = (props) => {
  const initialState = {
    cuotas: [],
    cuotasPrestamo: [],
    errorcuota: false,
    cuotaseleccionada: null,
  };

  //Crear dispatch y state
  const [state, dispatch] = useReducer(CuotaReducer, initialState);

  //Crear las funciones

  //Obtener las cuotas de un prestamo
  const obtenerCuotas = async (proceso) => {
    console.log(proceso);
    try {
      const resultado = await clienteAxios.get("/api/estado", {
        params: { proceso },
      });
      console.log(resultado);
      dispatch({
        type: CUOTAS_PRESTAMO,
        payload: resultado.data.estados,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Obtener las cuotas de todos los prestamos
  const obtenerCuotasTotales = async () => {
    try {
      const resultado = await clienteAxios.get("/api/cuotas");
      console.log(resultado);
      dispatch({
        type: CUOTAS_TOTALES,
        payload: resultado.data.clientes,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Agregar cuotas al prestamo seleccionado
  const agregarCuota = async (cuota) => {
    try {
      const resultado = await clienteAxios.post("/api/estado", cuota);
      console.log(resultado);
      dispatch({
        type: AGREGAR_CUOTA,
        payload: cuota,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Valida y muestra un error en caso de que sea necesario
  const validarCuota = () => {
    dispatch({
      type: VALIDAR_CUOTA,
    });
  };

  //Eliminar una cuota de un prestamo por su id
  const eliminarCuota = async (id, proceso) => {
    try {
      await clienteAxios.delete(`/api/estado/${id}`, { params: { proceso } });
      dispatch({
        type: ELIMINAR_CUOTA,
        payload: id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Actualizar y/o editar la cuota seleccionada
  const actualizarCuota = async (estado) => {
    try {
      const resultado = await clienteAxios.put(
        `/api/estado/${estado._id}`,
        estado
      );
      console.log(resultado);
      dispatch({
        type: ACTUALIZAR_CUOTA,
        payload: resultado.data.estado,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Extrae una cuota para su edicion
  const guardarCuotaActual = (estado) => {
    dispatch({
      type: CUOTA_ACTUAL,
      payload: estado,
    });
  };

  return (
    <CuotaContext.Provider
      value={{
        cuotas: state.cuotas,
        cuotasPrestamo: state.cuotasPrestamo,
        errorcuota: state.errorcuota,
        cuotaseleccionada: state.cuotaseleccionada,
        obtenerCuotas,
        agregarCuota,
        validarCuota,
        eliminarCuota,
        actualizarCuota,
        guardarCuotaActual,
        obtenerCuotasTotales,
      }}
    >
      {props.children}
    </CuotaContext.Provider>
  );
};

export default CuotaState;
