import {
  AGREGAR_PRESTAMO,
  VALIDAR_PRESTAMO,
  OBTENER_PRESTAMOS,
  PRESTAMO_ERROR,
  ELIMINAR_PRESTAMO,
  ACTUALIZAR_PRESTAMO,
  PRESTAMO_ACTUAL,
  OBTENER_PRESTAMOS_CUOTAS,
  ACTUALIZAR_PRESTAMO_ESTADO,
  OBTENER_PRESTAMO_CUOTAS,
  OBTENER_PROCESO_CLIENTE,
  RESULTADOS,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case OBTENER_PRESTAMOS:
      // console.log(action.payload)
      return {
        ...state,
        prestamos: action.payload,
      };

    case OBTENER_PROCESO_CLIENTE:
      // console.log(action.payload)
      return {
        ...state,
        procesoCliente: action.payload,
      };

    case OBTENER_PRESTAMOS_CUOTAS:
      console.log(action.payload);
      return {
        ...state,
        prestamoscuotas: action.payload,
      };

    case AGREGAR_PRESTAMO:
      return {
        ...state,
        prestamos: [...state.prestamos, action.payload],
        errorformulario: false,
      };

    case VALIDAR_PRESTAMO:
      return {
        ...state,
        errorformulario: true,
      };

    case PRESTAMO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    case ELIMINAR_PRESTAMO:
      return {
        ...state,
        prestamos: state.prestamos.filter(
          (prestamo) => prestamo._id !== action.payload
        ),
        prestamo: null,
      };

    case PRESTAMO_ACTUAL:
      return {
        ...state,
        prestamo: state.prestamos.filter(
          (prestamo) => prestamo._id === action.payload
        ),
        prestamoseleccionado: action.payload,
      };

    case ACTUALIZAR_PRESTAMO:
      return {
        ...state,
        prestamos: state.prestamos.map((prestamo) =>
          prestamo._id === action.payload._id ? action.payload : prestamo
        ),
        prestamoseleccionado: null,
      };

    case ACTUALIZAR_PRESTAMO_ESTADO:
      return {
        ...state,
        prestamos: state.prestamos.map((prestamo) =>
          prestamo._id === action.payload._id ? action.payload : prestamo
        ),
        prestamoseleccionado: null,
      };

    case RESULTADOS:
      return {
        ...state,
        resultado: action.payload,
      };

    default:
      return state;
  }
};
