import React, { useContext, useEffect } from "react";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import AlertaContext from "../../context/alerta/alertaContext";
import clienteContext from "../../context/cliente/clienteContext";
import MaterialTable from "material-table";
import { Alert, AlertTitle } from "@material-ui/lab";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import CuotaContext from "../../context/cuota/cuotaContext";

const useStyles = makeStyles((theme) => ({
  table: {
    paddingTop: "2rem",
    paddingRight: "1rem",
  },
}));

const PrestamoCuotas = () => {
  const classes = useStyles();
  const history = useHistory();

  //Context de prestamos
  const prestamosContext = useContext(PrestamoContext);
  const {
    prestamoscuotas,
    obtenerPrestamosCuotas,
    guardarPrestamoActual,
  } = prestamosContext;

  const clientesContext = useContext(clienteContext);
  const { mensaje, clientes, obtenerClientes } = clientesContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const CuotasContext = useContext(CuotaContext);
  const { cuotasPrestamo, obtenerCuotas } = CuotasContext;

  useEffect(() => {
    //Si hay un error
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      console.log(mensaje.msg);
    }
    ///  obtenerClientes();
    // obtenerPrestamosCuotas()
    // eslint-disable-next-line
  }, [mensaje]);

  //  console.log(clientes)

  //Extraemos los items que necesitamos de cliente
  var cliente_filtrado = [];
  for (var i = 0; i < clientes.length; i++) {
    cliente_filtrado.push({
      cedula: clientes[i].cedula,
      id_cliente: clientes[i]._id,
    });
  }

  //Unificacmos el array de prestamos con el de cliente filtrado
  let merged = [];
  for (let i = 0; i < prestamoscuotas.length; i++) {
    merged.push({
      ...prestamoscuotas[i],
      ...cliente_filtrado.find(
        (itmInner) => itmInner.id_cliente === prestamoscuotas[i].cliente
      ),
    });
  }

  //Agrega una presupuesto actual cuando el usuario desea editarlo
  const selectPrestamo = (prestamo) => {
    guardarPrestamoActual(prestamo);
    obtenerCuotas(prestamo._id);

    history.push("/admin/control-cuotas");
  };

  return (
    <>
      {/* Revisar si clientes tiene contenido */}
      {merged.length === 0 ? (
        <Alert severity="error">
          <AlertTitle>Advertencia</AlertTitle>
          <strong>No hay Prestamos</strong>, comienza creando uno en la sección
          Prestamos
        </Alert>
      ) : null}

      {alerta ? (
        <SnackbarContent message={alerta.msg} color={alerta.categoria} close />
      ) : null}

      <div className={classes.table}>
        <MaterialTable
          title="Listado de Prestamos"
          columns={[
            { title: "Codigo", field: "codigo", type: "numeric" },
            {
              title: "Nombre del Cliente",
              field: "nombre_cliente",
              width: 200,
            },

            {
              title: "Cedula",
              field: "cedula",
              type: "numeric",
            },

            {
              title: "Valor Empeño",
              field: "valor_prestamo",
              type: "currency",
            },

            { title: "Interes(%)", field: "interes", type: "numeric" },

            { title: "Fecha Inicio", field: "fecha_inicio", type: "date" },
            { title: "Fecha Fin", field: "fecha_fin", type: "date" },
          ]}
          data={merged}
          actions={[
            {
              icon: () => (
                <AddCircleOutlineIcon
                  color="primary"
                  style={{ color: green[500] }}
                />
              ),
              iconProps: { color: "primary" },
              tooltip: "Agregar Cuota",
              onClick: (event, rowData) => selectPrestamo(rowData),
            },
          ]}
          options={{
            search: true,
            actionsColumnIndex: -1,
            headerStyle: { paddingRight: "2rem" },
            // tableLayout:'auto'
            // filtering: true
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "Filas",
              labelRowsPerPage: "Filas por pagina:",
              firstAriaLabel: "Primera Pagina",
              firstTooltip: "Primera Pagina",
              previousAriaLabel: "Pagina Anterior",
              previousTooltip: "Pagina Anterior",
              nextAriaLabel: "Siguiente Pagina",
              nextTooltip: "Siguiente Pagina",
              lastAriaLabel: "Ultima Pagina",
              lastTooltip: "Ultima Pagina",
            },
            toolbar: {
              nRowsSelected: "{0} z(s) selected",
              searchPlaceholder: "Busqueda",
            },
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "Sin registros",
              addTooltip: "Agregar",
              editTooltip: "Editar",
              deleteTooltip: "Eliminar",

              editRow: {
                deleteText: "¿Está usted seguro de eliminar este cliente?",
                saveTooltip: "Guardar",
                cancelTooltip: "Cancelar",
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default PrestamoCuotas;
