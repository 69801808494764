import React, { Fragment, useContext, useEffect, useState } from "react";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import Fab from "@material-ui/core/Fab";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import CuotaContext from "../../context/cuota/cuotaContext";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boton: {
    backgroundColor: "#1a237e",
    borderRadius: "1rem",
    borderColor: "transparent",
  },
  cardTitleWhite: {
    color: "#000000",
    textAlign: "center",
    marginTop: theme.spacing(2.5),
    fontSize: "18px",
  },
  card: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "1rem",
  },
  botonAjustado: {
    paddingTop: "2rem",
    position: "relative",
  },
}));

const NuevoEstado = () => {
  const history = useHistory();
  const classes = useStyles();

  //Context de prestamos
  const prestamosContext = useContext(PrestamoContext);
  const {
    prestamo,
    resultado,
    prestamoseleccionado,
    actualizarEstadoPrestamo,
    guardarResultado,
  } = prestamosContext;

  const CuotasContext = useContext(CuotaContext);
  const {
    cuotaseleccionada,
    cuotasPrestamo,
    agregarCuota,
    validarCuota,
    actualizarCuota,
    obtenerCuotas,
  } = CuotasContext;

  const [estado, guadarEstado] = useState({
    actuacion: "",
    anotacion: "",
  });

  const [selectedDate, handleDateChange] = useState(new Date());

  const { actuacion, anotacion } = estado;

  const [error, guardarError] = useState(false);

  useEffect(() => {
    if (!prestamoseleccionado) {
      history.push("/admin/prestamo");
    }
  }, [prestamoseleccionado]);

  //effect que detecta si hay una cuota seleccionado
  useEffect(() => {
    if (cuotaseleccionada !== null) {
      guadarEstado(cuotaseleccionada);
      handleDateChange(cuotaseleccionada.fecha_actuacion);
    } else {
      guadarEstado({
        actuacion: "",
        anotacion: "",
      });
    }
  }, [cuotaseleccionada]);

  if (!prestamo) return null;

  //Lee los contenidos de los inputs
  const onChange = (e) => {
    guadarEstado({
      ...estado,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar la cuota
    if (actuacion.trim() === "" || anotacion.trim() === "") {
      guardarError(true);
      Swal.fire(
        "Alerta",
        "Favor completar los campos seleccionados",
        "warning"
      );
      return;
    }
    if (cuotaseleccionada === null) {
      estado.fecha_actuacion = selectedDate;
      estado.proceso = prestamoseleccionado._id;
      console.log(estado);
      agregarCuota(estado);
    } else {
      estado.fecha_actuacion = selectedDate;
      estado.proceso = prestamoseleccionado._id;
      console.log(estado);
      actualizarCuota(estado);
    }

    //Obtener y filtrar las cuotas del prestamo actual
    obtenerCuotas(prestamoseleccionado._id);

    //Reiniciar el form
    guadarEstado({
      actuacion: "",
      anotacion: "",
    });

    handleDateChange(new Date());
  };

  const resetearValores = () => {
    guadarEstado({
      actuacion: "",
      anotacion: "",
    });
    handleDateChange(new Date());
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CssBaseline />
            <div className={classes.paper}>
              <Card className={classes.card}>
                <div className={classes.botonAjustado}>
                  <Fab
                    color="secondary"
                    onClick={() => history.push("/admin/proceso")}
                    title="Regresar"
                    style={{
                      backgroundColor: "#9c27b0",
                    }}
                  >
                    <ArrowBackOutlinedIcon />
                  </Fab>
                </div>

                <Typography component="h1" variant="h5">
                  <p className={classes.cardTitleWhite}>
                    <strong>Cliente: </strong>{" "}
                    {prestamoseleccionado.nombre_cliente}{" "}
                  </p>
                  <p className={classes.cardTitleWhite}>
                    <strong>Codigo Proceso:</strong>{" "}
                    {prestamoseleccionado.codigo}{" "}
                  </p>
                  <p className={classes.cardTitleWhite}>
                    Adicione la actuación <br></br>
                  </p>
                </Typography>

                <form
                  className={classes.form}
                  noValidate
                  onSubmit={onSubmit}
                  margin="normal"
                >
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <DatePicker
                      inputVariant="outlined"
                      label="Fecha de Actuación"
                      margin="normal"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    required
                    error={error}
                    margin="normal"
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Actuación"
                    name="actuacion"
                    id="actuacion"
                    multiline
                    rows={3}
                    value={actuacion}
                    onChange={onChange}
                  />

                  <TextField
                    required
                    error={error}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="text"
                    label="Anotación"
                    name="anotacion"
                    value={anotacion}
                    onChange={onChange}
                    multiline
                    rows={4}
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {cuotaseleccionada
                          ? "Editar Estado"
                          : "Registrar Estado"}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Button
                        
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => resetearValores()}
                      >
                        Cancelar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </form>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </Container>
    </>
  );
};

export default NuevoEstado;
