import React, { useReducer } from "react";
import clienteContext from "./clienteContext";
import clienteReducer from "./clienteReducer";
import {
  AGREGAR_CLIENTE,
  VALIDAR_CLIENTE,
  OBTENER_CLIENTES,
  ACTUALIZAR_CLIENTE,
  ELIMINAR_CLIENTE,
  CLIENTE_ERROR,
  CLIENTE_ACTUAL,
  //  CLIENTE_NUEVO,
  CAMBIAR_ESTADO,
  RESET_VALORES,
} from "../../types";

import clienteAxios from "../../config/axios";

const ClienteState = (props) => {
  const initialState = {
    clientes: [],
    errorformulario: false,
    cliente: null,
    mensaje: null,
    clienteseleccionado: null,
    nuevapagina: false,
  };

  //Dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(clienteReducer, initialState);

  //Serie de funciones para el crud
  //Obtener los clientes
  const obtenerClientes = async () => {
    try {
      const resultado = await clienteAxios.get("/api/clientes");
      //    console.log(resultado)
      dispatch({
        type: OBTENER_CLIENTES,
        payload: resultado.data.clientes,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: CLIENTE_ERROR,
        payload: alerta,
      });
    }
  };

  //Agregar nuevo cliente
  const agregarCliente = async (cliente) => {
    console.log(cliente);
    try {
      const resultado = await clienteAxios.post("/api/clientes", cliente);
      console.log(resultado);

      //Insertar cliente en el state
      dispatch({
        type: AGREGAR_CLIENTE,
        payload: resultado.data,
      });
    } catch (error) {
      console.log(error);
      const alerta = {
        msg: error.response.data.msg,
        categoria: "danger",
      };
      dispatch({
        type: CLIENTE_ERROR,
        payload: alerta,
      });
    }
  };

  //Funcion para mostrar error cuando este vacio el cliente  y se de submit
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_CLIENTE,
    });
  };

  //Selecciona el cliente cuando el usuario le dio click
  const clienteActual = (clienteID) => {
    dispatch({
      type: CLIENTE_ACTUAL,
      payload: clienteID,
    });
  };

  //Elimina un cliente
  const eliminarCliente = async (clienteId) => {
    try {
      await clienteAxios.delete(`/api/clientes/${clienteId}`);
      dispatch({
        type: ELIMINAR_CLIENTE,
        payload: clienteId,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "danger",
      };
      dispatch({
        type: CLIENTE_ERROR,
        payload: alerta,
      });
    }
  };

  //Actualizar y/o editar el cliente seleccionado
  const actualizarCliente = async (cliente) => {
    try {
      const resultado = await clienteAxios.put(
        `/api/clientes/${cliente._id}`,
        cliente
      );
      //    console.log(resultado)
      dispatch({
        type: ACTUALIZAR_CLIENTE,
        payload: resultado.data.cliente,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Extraer un cliente para edicion
  const guardarClienteActual = (cliente) => {
    console.log(cliente);
    dispatch({
      type: CLIENTE_ACTUAL,
      payload: cliente,
    });
  };

  const cambiarEstado = () => {
    dispatch({
      type: CAMBIAR_ESTADO,
    });
  };

  const resetearValores = () => {
    dispatch({
      type: RESET_VALORES,
    });
  };

  return (
    <clienteContext.Provider
      value={{
        clientes: state.clientes,
        cliente: state.cliente,
        mensaje: state.mensaje,
        errorformulario: state.errorformulario,
        clienteseleccionado: state.clienteseleccionado,
        nuevapagina: state.nuevapagina,
        obtenerClientes,
        agregarCliente,
        mostrarError,
        clienteActual,
        eliminarCliente,
        actualizarCliente,
        guardarClienteActual,
        resetearValores,
        cambiarEstado,
      }}
    >
      {props.children}
    </clienteContext.Provider>
  );
};

export default ClienteState;
