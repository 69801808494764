/*eslint-disable*/
import React, { useState, useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AlertaContext from "../../context/alerta/alertaContext";
import AuthContext from "../../context/autenticacion/authContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import RingLoader from "react-spinners/RingLoader";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import fondo from "../../../src/assets/img/bg6.jpg";
import Card from "@material-ui/core/Card";
import Zoom from 'react-reveal/Zoom'

import InputLabel from "@material-ui/core/InputLabel";
import { css } from "@emotion/core";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.alfaroasociados.com.co" target="_blank">
        www.alfaroasociados.com.co
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginBottom: "0rem",
    paddingBottom: "2rem",
    border:'0.5rem'
  },
  card: {
    marginTop: "0rem",
    width: "auto",
    //  position: "aboslute",
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  align-items: center;
`;

const Login = (props) => {
  const classes = useStyles();
  const [timer, guardarTimer] = useState(null);

  //extraer los valores del context
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, iniciarSesion } = authContext;

  //En caso de que el usuario se haya autenticado o registrado o sea un registro duplicado

  //console.log(autenticado);

  useEffect(() => {
    if (autenticado && usuario.tipo === "administrador") {
      props.history.push("/admin/cliente");
    } else if (autenticado && usuario.tipo === "cliente")
      props.history.push("/cliente/estadosClientes");
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      guardarTimer(null);
    }

    // eslint-disable-next-line
  }, [mensaje, autenticado, props.history]);

  //State para iniciar sesion
  const [usuario, guardarUsuario] = useState({
    tipo: "",
    email: "",
    password: "",
  });

  //extraer de usuario
  const { email, password, tipo } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  //Cuando el usuario quiere iniciar sesion
  const onSubmit = (e) => {
    e.preventDefault();

    //Validar que no halla campos vacios
    if (email.trim() === "" || password.trim() === "") {
      mostrarAlerta("Todos los campos son obligatorios", "danger");
      return;
    }

    guardarTimer("Autenticando...");

    //Pasarlo al action
    iniciarSesion({ email, password, tipo });
  };

 

  return (
    <div>
      <div
        style={{
          backgroundImage: "url(" + fondo + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          width: "auto",
          height: "100vh",
        }}
      >
        <div
          style={{
            paddingTop: "4rem",
          }}
        >
          <Zoom>
          <Container maxWidth="xs">
            <CssBaseline />
        
            <Card className={classes.card}>
              <div className={classes.paper}>
                {timer ? (
                  <RingLoader
                    css={override}
                    size={80}
                    color={"#1294bc"}
                    loading="true"
                  />
                ) : null}
                {alerta ? (
                  <SnackbarContent
                    classes={classes.alerta}
                    message={alerta.msg}
                    color={alerta.categoria}
                    close
                  />
                ) : null}
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Iniciar Sesión
                </Typography>

                <form noValidate className={classes.form} onSubmit={onSubmit}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="tipo">Tipo de Usuario*</InputLabel>
                    <Select
                      autoFocus
                      labelId="tipo"
                      labelWidth={120}
                      id="tipo"
                      name="tipo"
                      label="Tipo de Usuario"
                      fullWidth
                      value={tipo}
                      onChange={onChange}
                    >
                      <MenuItem value="administrador">Administrador</MenuItem>
                      <MenuItem value="cliente">Cliente</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo Electronico"
                    name="email"
                    autoComplete="email"
                    onChange={onChange}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={onChange}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Iniciar Sesion
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="/restorepassword" variant="body2">
                        Olvidó su contraseña?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/nueva-cuenta" variant="body2">
                        {"No tienes una cuenta? Registrate"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Box>
                  <Copyright />
                </Box>
              </div>
            </Card>
    
          </Container>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default Login;
