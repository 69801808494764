import React, { useContext, useEffect, useState } from "react";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import Fab from "@material-ui/core/Fab";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer.js";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import Swal from "sweetalert2";
import CuotaContext from "../../context/cuota/cuotaContext";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  boton: {
    backgroundColor: "#1a237e",
    borderRadius: "1rem",
    borderColor: "transparent",
  },
  cardTitleWhite: {
    color: "#000000",
    textAlign: "center",
    marginTop: theme.spacing(2.5),
    fontSize: "18px",
  },
  card: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "1rem",
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(presupuesto) => {
        onChange({
          target: {
            name: props.name,
            value: presupuesto.value,
          },
        });
      }}
      allowNegative={false}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const NuevaCuota = () => {
  const history = useHistory();
  const classes = useStyles();

  //Context de prestamos
  const prestamosContext = useContext(PrestamoContext);
  const {
    prestamo,
    resultado,
    prestamoseleccionado,
    actualizarEstadoPrestamo,
    
  } = prestamosContext;

  const CuotasContext = useContext(CuotaContext);
  const {
    cuotaseleccionada,
  
    agregarCuota,
 
    actualizarCuota,
    obtenerCuotas,
  } = CuotasContext;

  const [cuota, guadarCuota] = useState({
    valor_cuota: "",
    tipo_cuota: "",
    //   fecha:'',
    observaciones: "",
  });

  const [selectedDate, handleDateChange] = useState(new Date());

  const { valor_cuota, observaciones, tipo_cuota } = cuota;

  const [error, guardarError] = useState(false);

  useEffect(() => {
    if (!prestamoseleccionado) {
      history.push("/admin/cuota");
    }
  }, [prestamoseleccionado]);

  //effect que detecta si hay una cuota seleccionado
  useEffect(() => {
    if (cuotaseleccionada !== null) {
      guadarCuota(cuotaseleccionada);
      handleDateChange(cuotaseleccionada.fecha);
    } else {
      guadarCuota({
        valor_cuota: "",
        observaciones: "",
      });
    }
  }, [cuotaseleccionada]);

  if (!prestamoseleccionado) return null;

  if (!prestamo) return null;

  //Lee los contenidos de los inputs
  const onChange = (e) => {
    guadarCuota({
      ...cuota,
      [e.target.name]: e.target.value,
    });
  };

  console.log("cuotaseleccionada: ", cuotaseleccionada);

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar la cuota
    if (valor_cuota.trim() === "" || tipo_cuota.trim() === "") {
      guardarError(true);
      Swal.fire(
        "Alerta",
        "Favor completar los campos seleccionados",
        "warning"
      );
      return;
    }

    // Si es nueva cuota
    if (cuotaseleccionada === null) {
      //Validar  cuotas de Capital
      if (tipo_cuota === "Abono Capital") {
        var nuevo_abono = resultado[2].abono_capital + parseFloat(valor_cuota);
        console.log("nuevo_abono: ", nuevo_abono);
      }

      //Validar  cuotas de Interes
      if (tipo_cuota === "Abono Interes") {
        var nuevo_interes =
          resultado[5].abono_interes +
          resultado[6].descuento +
          parseFloat(valor_cuota);
      }

      //Validar Descuento
      if (tipo_cuota === "Descuento") {
        var nuevo_descuento =
          resultado[5].abono_interes +
          resultado[6].descuento +
          parseFloat(valor_cuota);
      }

      //Alerta si cuota de capital ingresada es mayor a lo esperado
      if (nuevo_abono > resultado[0].capital) {
        Swal.fire(
          "Alerta",
          "El valor digitado de capital, supera la deuda pendiente",
          "warning"
        );
        return;
      }

      //Alerta si cuota de interes ingresada es mayor a lo esperado
      if (nuevo_interes > resultado[3].valor_interes_total) {
        Swal.fire(
          "Alerta",
          "El valor digitado de interés, supera la deuda pendiente",
          "warning"
        );
        return;
      }

      //Alerta si cuota de descuento ingresada es mayor a lo esperado
      if (nuevo_descuento > resultado[3].valor_interes_total) {
        Swal.fire(
          "Alerta",
          "El valor digitado de descuento, supera la deuda pendiente",
          "warning"
        );
        return;
      }
      //Terminar si saldo pendiente es igual a 0
      if (resultado[7].pendiente - parseFloat(valor_cuota) === 0) {
        console.log("Actualiza Estado");
        cuota.fecha = selectedDate;
        cuota.prestamo = prestamoseleccionado._id;
        agregarCuota(cuota);
        prestamoseleccionado.estado = "Terminado";
        actualizarEstadoPrestamo(prestamoseleccionado);
        Swal.fire("Terminado", "Credito terminado, con Exito", "success");
        setTimeout(() => {
          history.push("/admin/cuota");
        }, 2000);

        obtenerCuotas(prestamoseleccionado._id);
      } else {
        cuota.fecha = selectedDate;
        cuota.prestamo = prestamoseleccionado._id;
        agregarCuota(cuota);
      }
      obtenerCuotas(prestamoseleccionado._id);
    } else {
      //Validar  cuotas de Capital
      if (tipo_cuota === "Abono Capital") {
        var nuevo_abono =
          resultado[2].abono_capital +
          parseFloat(valor_cuota) -
          cuotaseleccionada.valor_cuota;
        console.log("nuevo_abono: ", nuevo_abono);
      }

      //Validar  cuotas de Interes
      if (tipo_cuota === "Abono Interes") {
        var nuevo_interes =
          resultado[5].abono_interes +
          resultado[6].descuento +
          parseFloat(valor_cuota) -
          cuotaseleccionada.valor_cuota;
      }

      //Validar Descuento
      if (tipo_cuota === "Descuento") {
        var nuevo_descuento =
          resultado[5].abono_interes +
          resultado[6].descuento +
          parseFloat(valor_cuota) -
          cuotaseleccionada.valor_cuota;
      }

      //Alerta si cuota de capital ingresada es mayor a lo esperado
      if (nuevo_abono > resultado[0].capital) {
        Swal.fire(
          "Alerta",
          "El valor digitado de capital, supera la deuda pendiente",
          "warning"
        );
        return;
      }

      //Alerta si cuota de interes ingresada es mayor a lo esperado
      if (nuevo_interes > resultado[3].valor_interes_total) {
        Swal.fire(
          "Alerta",
          "El valor digitado de interés, supera la deuda pendiente",
          "warning"
        );
        return;
      }

      //Alerta si cuota de descuento ingresada es mayor a lo esperado
      if (nuevo_descuento > resultado[3].valor_interes_total) {
        Swal.fire(
          "Alerta",
          "El valor digitado de descuento, supera la deuda pendiente",
          "warning"
        );
        return;
      }
      //Terminar si saldo pendiente es igual a 0
      if (
        resultado[7].pendiente -
          parseFloat(valor_cuota) -
          cuotaseleccionada.valor_cuota ===
        0
      ) {
        console.log("Actualiza Estado");
        cuota.fecha = selectedDate;
        cuota.prestamo = prestamoseleccionado._id;
        agregarCuota(cuota);
        prestamoseleccionado.estado = "Terminado";
        actualizarEstadoPrestamo(prestamoseleccionado);
        Swal.fire("Terminado", "Credito terminado, con Exito", "success");
        setTimeout(() => {
          history.push("/admin/cuota");
        }, 2000);

        obtenerCuotas(prestamoseleccionado._id);
      } else {
        //actualizar cuota existente
        cuota.fecha = selectedDate;
        cuota.prestamo = prestamoseleccionado._id;
        actualizarCuota(cuota);
      }
    }

    //Obtener y filtrar las cuotas del prestamo actual
    obtenerCuotas(prestamoseleccionado._id);

    //Reiniciar el form
    guadarCuota({
      valor_cuota: "",
      observaciones: "",
      tipo_cuota: "",
    });

    handleDateChange(new Date());
  };

  const aviso_error = () => {
    Swal.fire(
      "Alerta",
      "El valor digitado, supera la deuda pendiente",
      "warning"
    );
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <Fab
              color="secondary"
              onClick={() => history.push("/admin/cuota")}
              title="Regresar"
              style={{
                backgroundColor: "#9c27b0",
              }}
            >
              <ArrowBackOutlinedIcon />
            </Fab>
          </GridItem>

          <GridItem xs={12} sm={12} md={10}>
            <CssBaseline />
            <div className={classes.paper}>
              <Card className={classes.card}>
                <Typography component="h1" variant="h5">
                  <p className={classes.cardTitleWhite}>
                    <strong>Cliente: </strong>{" "}
                    {prestamoseleccionado.nombre_cliente}{" "}
                  </p>
                  <p className={classes.cardTitleWhite}>
                    <strong>Codigo Prestamo:</strong>{" "}
                    {prestamoseleccionado.codigo}{" "}
                  </p>
                  <p className={classes.cardTitleWhite}>
                    Adicione las cuotas <br></br>
                  </p>
                </Typography>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={onSubmit}
                  margin="normal"
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="tipo_cuota" error={error}>
                      Tipo de Cuota
                    </InputLabel>
                    <Select
                      native
                      error={error}
                      onChange={onChange}
                      label="Tipo de Cuota"
                      name="tipo_cuota"
                      id="tipo_cuota"
                      value={tipo_cuota}
                      autoFocus
                    >
                      <option aria-label="None" value="" />
                      <option value={"Abono Capital"}>Abono a Capital</option>
                      <option value={"Abono Interes"}>Abono a Interés</option>
                      <option value={"Descuento"}>Descuento de Interés</option>
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    error={error}
                    margin="normal"
                    fullWidth
                    type="text"
                    variant="outlined"
                    label="Valor de la Cuota"
                    name="valor_cuota"
                    id="valor"
                    value={valor_cuota}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />

                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <DatePicker
                      inputVariant="outlined"
                      label="Fecha de Abono"
                      margin="normal"
                      fullWidth
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>

                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    type="text"
                    label="Observaciones(Opcional)"
                    name="observaciones"
                    value={observaciones}
                    onChange={onChange}
                    multiline
                    rows={4}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    {cuotaseleccionada ? "Editar Cuota" : "Registrar Cuota"}
                  </Button>
                </form>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </Container>
    </>
  );
};

export default NuevaCuota;
