/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard";

import Procesos from "views/Procesos/Procesos";
import Cliente from "views/Cliente/Cliente";
import CrearProceso from "views/CrearProceso/CrearProceso";
import Electrodomesticos from "views/Electrodomesticos/Electrodomesticos";
import Vehiculos from "views/Vehiculos/Vehiculos";
import Estados from "views/Estados/Estados";
import Cuota from "views/Cuotas/Cuotas";
import Control_cuotas from "views/Control_cuotas/control_cuotas";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Estadisticas",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    invisible: "true",
  },
  {
    path: "/cliente",
    name: "Clientes",
    icon: "perm_identity",
    component: Cliente,
    layout: "/admin",
  },

  {
    path: "/proceso",
    name: "Procesos",
    icon: "account_balance",
    component: Procesos,
    layout: "/admin",
  },

  {
    path: "/estados",
    name: "Estados",
    icon: "account_balance",
    component: Estados,
    layout: "/admin",
    invisible: "true",
  },
  {
    path: "/cuota",
    name: "Cuotas",
    icon: "account_balance_wallet",
    component: Cuota,
    layout: "/admin",
    invisible: "true",
  },

  {
    path: "/crear_proceso",
    name: "Crear Proceso",
    icon: "account_balance",
    component: CrearProceso,
    layout: "/admin",
    invisible: "true",
  },
  {
    path: "/electrodomesticos",
    name: "Electrodomesticos",
    icon: "account_balance",
    component: Electrodomesticos,
    layout: "/admin",
    invisible: "true",
  },
  {
    path: "/vehiculos",
    name: "Vehiculos",
    icon: "account_balance",
    component: Vehiculos,
    layout: "/admin",
    invisible: "true",
  },

  {
    path: "/control-cuotas",
    name: "Control de Cuotas",
    icon: "account_balance",
    component: Control_cuotas,
    layout: "/admin",
    invisible: "true",
  },
];

export default dashboardRoutes;
