import React from "react";
import { Container } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import NuevaCuota from "./NuevaCuota";
import GridItem from "components/Grid/GridItem.js";
import SaldosCuotas from "./SaldosCuotas";
import ListadoCuotas from "./ListadoCuotas";

const ControlCuota = () => {
  return (
    <Container>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <NuevaCuota />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <SaldosCuotas />
          <ListadoCuotas />
        </GridItem>
      </GridContainer>
    </Container>
  );
};

export default ControlCuota;
