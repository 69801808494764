// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
//import Person from "@material-ui/icons/Person"

// core components/views for Admin layout
//import DashboardPage from "views/Dashboard/Dashboard.js";
import Client from "views/Client/Client";
import Actuaciones from "views/Client/Actuaciones";

const dashboardRoutes = [
  {
    path: "/cliente",
    name: "Procesos",
    rtlName: "لوحة القيادة",
    icon: "account_balance",
    component: Client,
    layout: "/cliente",
  },

  {
    path: "/actuaciones",
    name: "Estados",
    rtlName: "لوحة القيادة",
    icon: "account_balance",
    component: Actuaciones,
    layout: "/cliente",
    invisible: "true",
  },
];

export default dashboardRoutes;
