import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import "react-vertical-timeline-component/style.min.css";

// core components

import Login from "./componentsPrestamo/Auth/Login";
import NuevaCuenta from "./componentsPrestamo/Auth/nuevaCuenta";
import RestorePassword from "./componentsPrestamo/Auth/RestorePassword";
import ResetPassword from "./componentsPrestamo/Auth/ResetPassword";
import Contactenos from "./componentsPrestamo/Contactenos/Contactenos"
//import Joyeria from './componentsPrestamo/Prestamos/Joyeria'
import Admin from "layouts/Admin.js";
import Cliente from "layouts/Cliente.js";

import AuthState from "./context/autenticacion/authState";
import tokenAuth from "./config/tokenAuth";
import AlertaState from "context/alerta/alertaState";
import RutaPrivada from "./componentsPrestamo/rutas/RutaPrivada";
import ClienteState from "./context/cliente/clienteState";
import PrestamoState from "./context/prestamo/prestamoState";
import CuotaState from "./context/cuota/cuotaState";

//Revisar si tenemos un token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

//console.log(process.env.REACT_APP_BACKEND_URL);

function App() {
  return (
    <PrestamoState>
      <CuotaState>
        <ClienteState>
          <AlertaState>
            <AuthState>
              <Router>
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/nueva-cuenta" component={NuevaCuenta} />
                  <Route
                    exact
                    path="/restorepassword"
                    component={RestorePassword}
                  />
                  <Route
                    exact
                    path="/resetpassword/:token"
                    component={ResetPassword}
                  />
                   <Route
                    exact
                    path="/contactenos"
                    component={Contactenos}
                  />
                  <RutaPrivada path="/admin" component={Admin} />
                  <RutaPrivada path="/cliente" component={Cliente} />
                </Switch>
              </Router>
            </AuthState>
          </AlertaState>
        </ClienteState>
      </CuotaState>
    </PrestamoState>
  );
}

export default App;
