import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import fondo from "../../../src/assets/img/bg6.jpg";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@material-ui/icons/PhoneAndroidOutlined";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { TextField } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import AlertaContext from "../../context/alerta/alertaContext";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import ReCAPTCHA from "react-google-recaptcha";
import AuthContext from "../../context/autenticacion/authContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";

const useStyles = makeStyles((theme) => ({
  titulo: {
    color: "white",
    fontFamily: "Roboto Slab, Times New Roman, serif",
    fontWeight: "700",
    paddingLeft: "4rem",
    paddingTop: "2rem",
    marginTop: "30px",
    minHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      paddingLeft: "2rem",
    },
  },

  subtitulo: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "300",
    color: "white",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      paddingLeft: "2rem",
    },
  },

  contenedor: {
    background: "rgba(0, 0, 0, 0.5)",
    marginLeft: "3rem",
    marginRight: "3rem",
    marginTop: "4rem",
  },
  texto: {
    margin: theme.spacing(1, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "24px",
  },

  texto2: {
    margin: theme.spacing(1, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
  },

  title: {
    margin: theme.spacing(1, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
  },

  botones: {
    margin: theme.spacing(1, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    paddingBottom: "1rem",
  },

  producto: {
    marginTop: "4rem",
    marginRight: "3rem",
    paddingRight: "0rem",
    maxWidth: 500,
  },

  fondo: {
    backgroundImage: "url(" + fondo + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    width: "auto",
    height: "110vh",
  },
}));

const Contactenos = () => {
  const classes = useStyles();
  const history = useHistory();

  const authContext = useContext(AuthContext);
  const {infoContactenos, mensajeContactenos  } = authContext;


  const [aviso, setAviso] = useState("");
  const [tc, setTC] = useState(false);

  const[captcha, setCaptcha] = useState();

  //State para guardar informacion
  const [contactenos, guardarContactenos] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    correo: "",
    mensaje: "",
  });


  useEffect(() => {
         if(mensajeContactenos){
      setTimeout(() => {
      history.push("/");
     }, 3000);
      }
  }, [mensajeContactenos])


  //extraer de usuario
  const { nombre, apellido, telefono, correo, mensaje } = contactenos;

  const onChange = (e) => {
    guardarContactenos({
      ...contactenos,
      [e.target.name]: e.target.value,
    });
  };

  const onChange2 = (e) => {
    setCaptcha(e);
  };

  //Cuando el usuario quiere iniciar sesion
  const onSubmit = (e) => {
    e.preventDefault();

    //Validar que no halla campos vacios
    if (
      nombre.trim() === "" ||
      apellido.trim() === "" ||
      telefono.trim() === "" ||
      correo.trim() === "" ||
      mensaje.trim() === ""
    ) {
      setTC(true);
      setAviso("Todos los campos son obligatorios");

      return;
    }

    if(  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(
                  correo
                ) === false){

                   setTC(true);
      setAviso("Digite un correo valido");

      return;
                }

    
      console.log(captcha)
    if(captcha === false || captcha === undefined ){
      console.log("Aqui entro")
      console.log(captcha)
       setTC(true);
      setAviso("complete el captcha");
      return;
    }

    

    //Pasarlo al action
    infoContactenos(contactenos);
    

   guardarContactenos({
    nombre: "",
    apellido: "",
    telefono: "",
    correo: "",
    mensaje: "",

     })

  

  };


  return (
    <div>
      <div className={classes.fondo}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignContent: "center",
            
            }}
          >
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message={aviso}
              open={tc}
              closeNotification={() => setTC(false)}
              close
            />
             {mensajeContactenos ? (
               <SnackbarContent
                style={{
                  alignContent: "center",
                }}
                message={mensajeContactenos}
                color="success"
                close
              />
            ) : null}
            </div>
          </GridItem>
          
          <GridItem md={6} xs={12}>
            <div className={classes.contenedor}>
              <h2 className={classes.titulo}>Contáctese con Nosotros</h2>
              <h4 className={classes.subtitulo}>
                ¿Necesitas más información? <br /> Compruebe lo que dicen otras
                personas sobre nuestros servicios.
              </h4>
              <h5 className={classes.subtitulo}>
                <LocationOnOutlinedIcon color="primary" /> Visítenos en <br />
                &nbsp; &nbsp; &nbsp;Cartagena, Colombia <br></br> &nbsp; &nbsp;
                &nbsp;Centro Comercial La Matuna <br />
                &nbsp; &nbsp; &nbsp;Oficina 209
              </h5>
              <h5
                className={classes.subtitulo}
                style={{ paddingBottom: "2rem" }}
              >
                <PhoneAndroidOutlinedIcon color="primary" /> LLámenos al
                <br />
                &nbsp; &nbsp; &nbsp;+(57) 3017560348
              </h5>
            </div>
          </GridItem>
          <GridItem md={6} xs={12}>
            <form noValidate className={classes.form} onSubmit={onSubmit}>
              <Card className={classes.producto}>
                <CardHeader color="primary" style={{ background: "#8a130aeb" }}>
                  <h4 className={classes.texto}>Contáctenos</h4>
                  <p className={classes.texto2}>
                    Complete los siguientes campos
                  </p>
                </CardHeader>

                <CardBody>
                  <h4 className={classes.title}>
                    Digite la información solicitada{" "}
                  </h4>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="Nombre"
                        label="Nombre"
                        name="nombre"
                        value={nombre}
                        onChange={onChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="apellido"
                        label="Apellido"
                        name="apellido"
                        autoComplete="apellido"
                        value={apellido}
                        onChange={onChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="telefono"
                        label="Telefono"
                        name="telefono"
                        autoComplete="telefono"
                        value={telefono}
                        onChange={onChange}
                        type='number'
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="correo"
                        label="Correo"
                        name="correo"
                        value={correo}
                        onChange={onChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="mensaje"
                        label="Mensaje"
                        name="mensaje"
                        autoComplete="mensaje"
                        rows={3}
                        multiline={true}
                        value={mensaje}
                        onChange={onChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <ReCAPTCHA
                        sitekey="6LeCrvoZAAAAAFOgRe0n-RlhWvROd_N1rWHNpTLG"
                        onChange={onChange2}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={classes.botones}>
                        <Button
                          size="md"
                          variant="contained"
                          color="danger"
                          style={{ background: "#271c8de6" }}
                          type="submit"
                        
                        >
                          Enviar
                        </Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default Contactenos;