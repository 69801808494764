//Registro e inicio de sesion
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO";
export const REGISTRO_ERROR = "REGISTRO_ERROR";
export const OBTENER_USUARIO = "OBTENER_USUARIO";
export const LOGIN_EXITOSO = "LOGIN_EXITOSO";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const CERRAR_SESION = "CERRAR_SESION";

//Alertas
export const MOSTRAR_ALERTA = "MOSTRAR_ALERTA";
export const OCULTAR_ALERTA = "OCULTAR_ALERTA";

//Clientes
export const AGREGAR_CLIENTE = "AGREGAR_CLIENTE";
export const VALIDAR_CLIENTE = "VALIDAR_CLIENTE";
export const OBTENER_CLIENTES = "OBTENER_CLIENTES";
export const ACTUALIZAR_CLIENTE = "ACTUALIZAR_CLIENTE";
export const ELIMINAR_CLIENTE = "ELIMINAR_CLIENTE";
export const CLIENTE_ERROR = "CLIENTE_ERROR";
export const CLIENTE_ACTUAL = "CLIENTE_ACTUAL";
export const CAMBIAR_ESTADO = "CAMBIAR_ESTADO";
export const RESET_VALORES = "RESET_VALORES";

//Prestamos
export const AGREGAR_PRESTAMO = "AGREGAR_PRESTAMO";
export const VALIDAR_PRESTAMO = "VALIDAR_PRESTAMO";
export const OBTENER_PRESTAMOS = "OBTENER_PRESTAMOS";
export const PRESTAMO_ERROR = "PRESTAMO_ERROR";
export const ELIMINAR_PRESTAMO = "ELIMINAR_PRESTAMO";
export const ACTUALIZAR_PRESTAMO = "ACTUALIZAR_PRESTAMO";
export const PRESTAMO_ACTUAL = "PRESTAMO_ACTUAL";
export const OBTENER_PRESTAMOS_CUOTAS = "OBTENER_PRESTAMOS_CUOTAS";
export const ACTUALIZAR_PRESTAMO_ESTADO = "ACTUALIZAR_PRESTAMO_ESTADO";

//Cuotas
export const CUOTAS_PRESTAMO = "CUOTAS_PRESTAMO";
export const AGREGAR_CUOTA = "AGREGAR_CUOTA";
export const VALIDAR_CUOTA = "VALIDAR_CUOTA";
export const ELIMINAR_CUOTA = "ELIMINAR_CUOTA";
export const ACTUALIZAR_CUOTA = "ACTUALIZAR_CUOTA";
export const CUOTA_ACTUAL = "CUOTA_ACTUAL";
export const CUOTAS_TOTALES = "CUOTAS_TOTALES";

//RESULTADO
export const RESULTADOS = "RESULTADOS";

export const OBTENER_PROCESO_CLIENTE = "OBTENER_PROCESO_CLIENTE";
export const ACTUALIZAR_CONTRASEÑA = "ACTUALIZAR_CONTRASEÑA";
export const RESET_CONTRASEÑA = "RESET_CONTRASEÑA";

export const RESTORE_ERROR = "RESTORE_ERROR";
export const RESET_ERROR = "RESET_ERROR";


//Contactenos
export const CONTACTENOS = "CONTACTENOS"
