import {    
    AGREGAR_CLIENTE,
    VALIDAR_CLIENTE,
    OBTENER_CLIENTES,
    ACTUALIZAR_CLIENTE,
    ELIMINAR_CLIENTE,
    CLIENTE_ERROR,
    CLIENTE_ACTUAL,
    CAMBIAR_ESTADO,
    RESET_VALORES,
    
  } from '../../types'

export default (state, action) => {
switch(action.type){
   
        case OBTENER_CLIENTES:
          //  console.log(action.payload)
        return {
            ...state,
            clientes: action.payload
        }

        case AGREGAR_CLIENTE:
        return {
            ...state,
            clientes: [...state.clientes, action.payload],
            errorformulario: false,
        }

        case VALIDAR_CLIENTE:
        return {
            ...state,
           errorformulario: true
        }

        case CLIENTE_ACTUAL:
        return {
            ...state,
            cliente: state.clientes.filter(cliente => cliente._id === action.payload),
            clienteseleccionado: action.payload
        }

        case ELIMINAR_CLIENTE:
        return {
            ...state,
            clientes: state.clientes.filter(cliente => cliente._id !== action.payload),
            cliente:null
        }

        case CLIENTE_ERROR:
        return{
            ...state,
            mensaje: action.payload
        }

        case ACTUALIZAR_CLIENTE:
        return{
            ...state,
            clientes: state.clientes.map(cliente => cliente._id === action.payload._id ? action.payload : cliente),
            clienteseleccionado: null,
            nuevapagina: false
        }   
            
      
        case CAMBIAR_ESTADO:
        return {
            ...state,
            clienteseleccionado: null,
            nuevapagina: false,
        }

        case RESET_VALORES:
        return {
            ...state,
            clienteseleccionado: null,
            nuevapagina: false
            }


    default: 
        return state;
}
}