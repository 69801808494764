import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import CuotaContext from "../../context/cuota/cuotaContext";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import Card from "components/Card/Card";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/css/VerticalTimeline.css";
import "../../assets/css/VerticalTimelineElement.css";
import "../../assets/css/main.css";
import StarIcon from "@material-ui/icons/Star";
import InsideTimeLine from "./InsideTimeLine";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    paddingBottom: "1rem",
    background: "#cecece",
  },
  texto: {
    textAlign: "center",
    fontWeight: "bold",
  },
  parrafo: {
    fontWeight: "bold",
  },
}));

const Timeline = () => {
  const classes = useStyles();
  const history = useHistory();
  //Context de prestamos
  const prestamosContext = useContext(PrestamoContext);
  const { prestamoseleccionado } = prestamosContext;

  const CuotasContext = useContext(CuotaContext);
  const { obtenerCuotas, cuotasPrestamo, eliminarCuota } = CuotasContext;

  useEffect(() => {
    if (!prestamoseleccionado) {
      history.push("/admin/prestamo");
      return;
    }
    obtenerCuotas(prestamoseleccionado._id);
  }, [prestamoseleccionado]);

  return (
    <>
      {cuotasPrestamo.length === 0 ? (
        <Alert severity="error">
          <AlertTitle>Advertencia</AlertTitle>
          <strong>No hay actuaciones del proceso</strong>, comienza creando uno
        </Alert>
      ) : (
        <Card className={classes.card}>
          <h3 className={classes.texto}>Actuaciones del Proceso</h3>
          <VerticalTimeline>
            {cuotasPrestamo.map((estados) => (
              <InsideTimeLine key={estados._id} estados={estados} />
            ))}

            <VerticalTimelineElement
              iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
              icon={<StarIcon />}
            />
          </VerticalTimeline>
        </Card>
      )}
    </>
  );
};

export default Timeline;
