import React from "react";
import NuevoEstado from "../../componentsPrestamo/Estados/NuevoEstado";
import TimeLine from "../../componentsPrestamo/Estados/TimeLine";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const Estados = () => {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <NuevoEstado />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <TimeLine />
      </GridItem>
    </GridContainer>
  );
};

export default Estados;
