import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/autenticacion/authContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import logo from "../../assets/img/bg5.jpg";
import Container from "@material-ui/core/Container";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import AlertaContext from "../../context/alerta/alertaContext";

const useStyles = makeStyles((theme) => ({}));

const RestorePassword = () => {
  const history = useHistory();
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const { restorePassword, correo, mensaje, error_alert } = authContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  useEffect(() => {
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }

    // eslint-disable-next-line
  }, [mensaje]);

  const [data, setData] = useState({
    email: "",
  });

  const { email } = data;

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar que no halla campos vacios
    if (email.trim() === "") {
      console.log("El email es obligatorio");
      mostrarAlerta("El email es obligatorio", "danger");
      return;
    }

    //Pasarlo al action
    restorePassword({
      email,
    });
  };

  if (correo) {
    setTimeout(() => {
      history.push("/");
    }, 7000);
  }

  return (
    <>
      <GridContainer>
        <img
          style={{
            maxHeight: "auto",
            maxWidth: "auto",
            height: "250px",
            width: "1400px",
            boxShadow: "0px 10px 15px 0px rgba(87,25,25,1)",
          }}
          src={logo}
        />

        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignContent: "center",
              
            }}
          >
            {alerta ? (
              <SnackbarContent
                classes={classes.alerta}
                message={alerta.msg}
                color={alerta.categoria}
                close
              />
            ) : null}
            {correo ? (
              <SnackbarContent
                style={{
                  alignContent: "center",
                }}
                message={correo}
                color="success"
                close
              />
            ) : null}

            <p
              style={{
                paddingTop: "2rem",
                lineHeight: "0em",
                fontSize: "calc(1em + 1vw)",
              }}
            >
              <strong>Alfaro Abogados Asociados</strong>
            </p>
            <p
              style={{
                paddingTop: "0rem",
                lineHeight: "0em",
                fontSize: "calc(0.5em + 1vw)",
              }}
            >
              Bienvenido(a) a nuestro sistema para restaurar contraseñas{" "}
            </p>

            <p
              style={{
                paddingTop: "0rem",
                lineHeight: "0em",
                fontSize: "calc(0.5em + 1vw)",
              }}
            >
              Digite el correo electronico registrado en nuestra base de datos
            </p>
          </div>
        </GridItem>

        <Container
          maxWidth="lg"
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
            
          }}
        >
          <form onSubmit={onSubmit} noValidate>
            <TextField
              fullWidth
              autoFocus
              type="email"
              variant="outlined"
              margin="normal"
              required
              id="email"
              label="Correo Electronico"
              name="email"
              value={email}
              autoComplete="email"
              onChange={onChange}
            />
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
              >
                Restaurar Contraseña
              </Button>
              <Button
                style={{ marginLeft: "2rem" }}
                type="submit"
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => history.push("/")}
              >
                Cancelar
              </Button>
            </div>
          </form>
        </Container>
      </GridContainer>
    </>
  );
};

export default RestorePassword;
