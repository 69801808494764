import React from 'react';
import PrestamoCuotas from '../../componentsPrestamo/Cuotas/PrestamoCuotas'


const Cuotas = () => {
    return ( 
        <PrestamoCuotas/>
     );
}
 
export default Cuotas;