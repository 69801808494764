import React, { Fragment,useContext,useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import PrestamoContext from '../../context/prestamo/prestamoContext'
import  { useHistory} from 'react-router-dom'
import CuotaContext from '../../context/cuota/cuotaContext'
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment/moment.js';
import { idText } from 'typescript';
import { func, object } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    saldos: {
      marginTop: theme.spacing(3)
    },
    avisos:{
      paddingTop:'0rem'
    }

  }));


  

const SaldosCuotas = () => {
  const classes = useStyles();
  const history = useHistory();

  const prestamosContext = useContext(PrestamoContext)
  const {prestamoseleccionado, guardarResultado} = prestamosContext;

  const CuotasContext = useContext(CuotaContext);
  const{cuotasPrestamo } = CuotasContext



  useEffect(() => {
    if(!prestamoseleccionado){
      history.push('/admin/cuota')
    }
   }, [prestamoseleccionado])


useEffect(() => {
 guardarResultado(resultados)
 //console.log(resultados)
}, [descuento_interes(), abonos_capital(), abonos_interes()])

  if(!prestamoseleccionado) return null

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })


  //Calculamos los abonos al capital
  function abonos_capital() {
    let total= 0;
           for(let i=0; i< cuotasPrestamo.length; i++){
             if(cuotasPrestamo[i].tipo_cuota === 'Abono Capital'){
                 total+= parseInt(cuotasPrestamo[i].valor_cuota);
             }
            }
            return total
  } 
  abonos_capital()



    //Descuento de Interes
    function descuento_interes() {
      let total= 0;
             for(let i=0; i< cuotasPrestamo.length; i++){
               if(cuotasPrestamo[i].tipo_cuota === 'Descuento'){
                   total+= parseInt(cuotasPrestamo[i].valor_cuota);
               }
              }
              return total
    } 
    descuento_interes()

  function calcular_interes_total() {
   let mes1 = 0 
   let mes2=0
   let mes3=0
   let mes4=0
   let mes5=0
   let mes6=0
   let mes7=0
   let mes8=0
   let mes9=0
   let mes10=0
   let mes11=0
   let mes12=0
   let mes13=0
   
   let total= 0;
   var array= []
   var myArrClean =[]
   mes1 = ((prestamoseleccionado.valor_prestamo)*prestamoseleccionado.interes)/100
 
  
          for(let i=0; i< cuotasPrestamo.length; i++){
              var fecha_inicio = new Date(prestamoseleccionado.fecha_inicio)
              if(cuotasPrestamo[i].tipo_cuota === 'Abono Capital'){
                var fecha_abono = new Date(cuotasPrestamo[i].fecha) 
                // console.log(cuotasPrestamo[i].valor_cuota) 
           
             
              var diffTime = Math.abs(fecha_abono - fecha_inicio)
              var dias = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) 


              
            //  console.log(dias)
            if(dias <= 30){
                total+= parseInt(cuotasPrestamo[i].valor_cuota);
              //  console.log(total)
                if(prestamoseleccionado.valor_prestamo === total){
                  mes2 = 0
               //   console.log("Aquiiiii")
                }else{
                //  console.log('total: ',total)
                  mes2 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
                //  console.log('Mes2: ',mes2)

                }
               
            }
            else if(dias > 30 && dias <=60){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
            
              if(prestamoseleccionado.valor_prestamo === total){
                mes3 = 0
               // console.log("Aquiiiii2")
              }else{
                mes3 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
              
            }
            else if(dias > 60 && dias <=90){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes4 = 0
              }else{
                mes4 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
              
        
            }else if(dias > 90 && dias <=120){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes5 = 0
              }else{
                mes5 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
             
        
            }else if(dias > 120 && dias <=150){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes6 = 0
              }else{
                mes6 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
        
            }else if(dias > 150 && dias <=180){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes7 = 0
              }else{
                mes7 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
        
            }else if(dias > 210 && dias <=240){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes8 = 0
              }else{
                mes8 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
        
            }else if(dias > 240 && dias <=270){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes9 = 0
              }else{
                mes9 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
        
            }else if(dias > 270 && dias <=300){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes10 = 0
              }else{
                mes10 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
        
            }else if(dias > 300 && dias <=330){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes11 = 0
              }else{
                mes11 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
        
            }else if(dias > 330 && dias <=360){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes12 = 0
              }else{
                mes12 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
        
            }else if(dias > 360 && dias <=390){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes13 = 0
              }else{
                mes13 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100

              }
            }

           }
          }
             array.push(mes1, mes2, mes3, mes4, mes5, mes6, mes7,mes8, mes9, mes10, mes11, mes12)
          //   console.log(array)
             myArrClean = array.filter(Boolean)
           //  console.log(myArrClean)
            
             var meses = prestamoseleccionado.meses
             var size = myArrClean.length //[100.000]
            // console.log(size)
            

             var diferencia = meses - (size)//3
            // console.log(diferencia)
           //  console.log('Valor: ', myArrClean[0])

             var meses_restantes_valor_interes = myArrClean[myArrClean.length -1] * diferencia //300.000
            
             var suma_meses_antes = 0
             for(let i=0; i< myArrClean.length; i++){

               // console.log(myArrClean[i])
                 suma_meses_antes += parseInt(myArrClean[i]) //100.000
               //  console.log('suma_meses_antes: ', suma_meses_antes)
             } 
            //  console.log('suma_meses_antes: ', suma_meses_antes)

              var total_interes_acumulado = meses_restantes_valor_interes + suma_meses_antes

            // console.log('Total Interes: ',meses_restantes_valor_interes + suma_meses_antes)
          
             
             return total_interes_acumulado
          
 } 
 calcular_interes_total()

  //Calculamos los abonos al interes
  function abonos_interes() {
  //  console.log('cuotasPrestamo: ', cuotasPrestamo)
   let total= 0;
          for(let i=0; i< cuotasPrestamo.length; i++){
            if(cuotasPrestamo[i].tipo_cuota === 'Abono Interes'){
                total+= parseInt(cuotasPrestamo[i].valor_cuota);
            }
           }
          // console.log('total: ', total)
           return total
          
 } 
 abonos_interes()

//  const dia = 29
//  const today =  new Date()
//  const newdate = new Date;
//  newdate.setDate(today.getDate()+dia);
  
//  var hoy = newdate;
//  //var hoy =  new Date()
//   console.log('Hoy: ',hoy)
//   var inicio =new Date(prestamoseleccionado.fecha_inicio) 

//  const diffTime = Math.abs(hoy - inicio);
//  const dias = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 


 
 const capital = (formatter.format(prestamoseleccionado.valor_prestamo)) 
 const abono_capital = (formatter.format(abonos_capital())) 
 const saldo_capital= (formatter.format(prestamoseleccionado.valor_prestamo - abonos_capital())) 

  // var interes_mensual1 = (prestamoseleccionado.valor_prestamo * prestamoseleccionado.interes)/100
  // var interes_mensual = ((prestamoseleccionado.valor_prestamo - abonos_capital())* prestamoseleccionado.interes)/100
  // var cuota_interes = 0
 
  // if(dias <= 30){

  //   cuota_interes = interes_mensual1 ;
  //   // var acum = cuota_interes

  //   }else {
  //     // console.log(acum)
  //     cuota_interes = interes_mensual ;

  //   }


   
  function calcular_interes_mes(){
   
    let mes1 = 0 
    let mes2=0
    let mes3=0
    let mes4=0
    let mes5=0
    let mes6=0
    let mes7=0
    let mes8=0
    let mes9=0
    let mes10=0
    let mes11=0
    let mes12=0
    let mes13=0
    let total= 0;
    var array= []
    var myArrClean =[]
    mes1 = ((prestamoseleccionado.valor_prestamo)*prestamoseleccionado.interes)/100
  
   
           for(let i=0; i< cuotasPrestamo.length; i++){
               var fecha_inicio = new Date(prestamoseleccionado.fecha_inicio)
               
               if(cuotasPrestamo[i].tipo_cuota === 'Abono Capital'){
                 var fecha_abono = new Date(cuotasPrestamo[i].fecha) 
                
           
              
               var diffTime = Math.abs(fecha_abono - fecha_inicio)
               var dias = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) 
               
              
             if(dias <= 30){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes2 = 0
              }else{
                mes2 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
             }
             else if(dias > 30 && dias <=60){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes3 = 0
              }else{
                mes3 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
              
             }
             else if(dias > 60 && dias <=90){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes4 = 0
              }else{
                mes4 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
               
         
             }else if(dias > 90 && dias <=120){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes5 = 0
              }else{
                mes5 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
              
         
             }else if(dias > 120 && dias <=150){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes6 = 0
              }else{
                mes6 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 150 && dias <=180){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes7 = 0
              }else{
                mes7 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 210 && dias <=240){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes8 = 0
              }else{
                mes8 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 240 && dias <=270){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes9 = 0
              }else{
                mes9 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 270 && dias <=300){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes10 = 0
              }else{
                mes10 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 300 && dias <=330){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes11 = 0
              }else{
                mes11 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 330 && dias <=360){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes12 = 0
              }else{
                mes12 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
         
             }else if(dias > 360 && dias <=390){
              total+= parseInt(cuotasPrestamo[i].valor_cuota);
              if(prestamoseleccionado.valor_prestamo === total){
                mes13 = 0
              }else{
                mes13 = ((prestamoseleccionado.valor_prestamo- total)*prestamoseleccionado.interes)/100
              }
             }
            }
            }
             
              array.push(mes1, mes2, mes3, mes4, mes5, mes6, mes7,mes8, mes9, mes10, mes11, mes12)
             
              myArrClean = array.filter(Boolean)
             // console.log(myArrClean)

              // const dia = 31
              // const today =  new Date()
              // const newdate = new Date;
              // newdate.setDate(today.getDate()+dia);
              // var hoy = newdate;
              var hoy =  new Date()
             // console.log('Hoy Si es: ',hoy)
               var inicio =new Date(prestamoseleccionado.fecha_inicio) 
             
              const diffTime2 = Math.abs(hoy - inicio);
              const dias2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24)) -1; 
             // console.log('Dias2: ', dias2)
              var interes_mes= 0

           

           if(dias2 <= 30){
             
             interes_mes = myArrClean[0]
               
           }
           else if(dias2 > 30 && dias2 <=60){
            if(!myArrClean[1]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
              interes_mes = myArrClean[1]
             }
           
           }
           else if(dias2 > 60 && dias2 <=90){
            if(!myArrClean[2]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[2]
          }
       
           }else if(dias2 > 90 && dias2 <=120){
            if(!myArrClean[3]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[3]
          }
          
       
           }else if(dias > 120 && dias <=150){
            if(!myArrClean[4]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[4]
          }
       
           }else if(dias > 150 && dias <=180){
            if(!myArrClean[5]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[5]
          }
            
       
           }else if(dias > 210 && dias <=240){
            if(!myArrClean[6]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[6]
          }
           
           }else if(dias > 240 && dias <=270){
            if(!myArrClean[7]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[7]
          }
            
           }else if(dias > 270 && dias <=300){
            if(!myArrClean[8]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[8]
          }
            
           }else if(dias > 300 && dias <=330){
            if(!myArrClean[9]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[9]
          }
            
       
           }else if(dias > 330 && dias <=360){
            if(!myArrClean[10]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[10]
          }
            
       
           }else if(dias > 360 && dias <=390){
            if(!myArrClean[11]){
              interes_mes =  myArrClean[myArrClean.length- 1]
             }else{
             interes_mes = myArrClean[11]
          }
            
           }


              
              return interes_mes
}

calcular_interes_mes()

  const valor_interes = (formatter.format(calcular_interes_total()))
  const interes_pagar_mes = (formatter.format(calcular_interes_mes()))
  const abono_interes = (formatter.format( abonos_interes()) )
  const saldo_interes = (formatter.format(calcular_interes_total() - abonos_interes() - descuento_interes() ) )

  const descuento = (formatter.format(descuento_interes()) )
  const pendiente = formatter.format(( prestamoseleccionado.valor_prestamo - abonos_capital()) + (calcular_interes_total() - abonos_interes()) - descuento_interes() )
    
  var resultados=[];
  resultados.push(
    {capital : prestamoseleccionado.valor_prestamo},
    {saldo_capital : prestamoseleccionado.valor_prestamo - abonos_capital()},
    {abono_capital : abonos_capital()},
    {valor_interes_total : calcular_interes_total()},
    {saldo_interes : calcular_interes_total() - abonos_interes() - descuento_interes()},
    {abono_interes : abonos_interes()},
    {descuento : descuento_interes()},
    {pendiente :( prestamoseleccionado.valor_prestamo - abonos_capital()) + (calcular_interes_total() - abonos_interes()) - descuento_interes()}, 
    )

  
  

//  console.log(resultados)
 



    return ( 
        <Fragment>
        <Container className={classes.saldos}>
          <Alert severity="info">Capital: {capital}
                            <p> Abono Capital: {abono_capital}</p> 
                            <p style={{ color:'red'}} > Saldo Capital: {saldo_capital}</p>
          </Alert> 

          <Alert className={classes.avisos} 
                severity="success"> Valor Interes Total: {valor_interes} 
                                    <p>Valor Interes Mes: {interes_pagar_mes}</p> 
                                    <p>Abono Interes: {abono_interes}</p> 
                                    <p>Valor de Descuento de Interes: {descuento}</p>
                                    <p style={{ color:'red'}} > Saldo Interes: { saldo_interes}</p>
          
          </Alert>
          <Alert severity="error">
            
             Pendiente: {pendiente} </Alert>
        </Container>
    </Fragment>

     );
}
 
export default SaldosCuotas;