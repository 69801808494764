/*eslint-disable*/
import React, { useContext, useEffect, useState, Fragment } from "react";
import clienteContext from "../../context/cliente/clienteContext";
import AlertaContext from "../../context/alerta/alertaContext";
import AuthContext from "../../context/autenticacion/authContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { Alert, AlertTitle } from "@material-ui/lab";
import styled from "styled-components";
import Button from "components/CustomButtons/Button.js";
import DataTable from "react-data-table-component";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { MdPeopleOutline, MdBorderLeft } from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import MaterialTable from "material-table";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CuotaContext from "../../context/cuota/cuotaContext";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "red",
  },
  table: {
    paddingTop: "2rem",
  },
  producto: {
    borderRadius: "1rem",
    borderColor: "transparent",
    boxShadow: "0 14px 28pxrgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    width: "1000px",
    display: "flex",
    left: "10%",
  },
  title: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "red",
    fontWeight: "bold",
  },
  contenedor: {
    backgroundColor: "#EAECEE",
    borderTop: "1px solid #5499C7",
    borderBottom: "1.5px solid #5499C7",
    borderRight: "1.5px solid #5499C7",
    borderLeft: "4px solid red",
    borderRadius: "10px",
    paddingTop: "2rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "4rem",
    height: "auto",
    marginBottom: "2rem",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
  contenedorInterno: {
    marginLeft: "2rem",
    paddingBottom: "2rem",
  },
  numero: {
    color: "red",
    fontWeight: "bold",
  },
}));

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black", // (default alpha is 0.38)
    },
  },
})(TextField);

const ProcesoCliente = () => {
  const classes = useStyles();
  const history = useHistory();

  const prestamosContext = useContext(PrestamoContext);
  const {
    procesoCliente,
    mensaje,
    obtenerProcesosClientes,
    guardarPrestamoActual,
  } = prestamosContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const clientesContext = useContext(clienteContext);
  const { clientes, obtenerClientes } = clientesContext;

  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado } = authContext;

  //Obtener clientes cuando carga el componente
  useEffect(() => {
    //Si hay un error
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      console.log(mensaje.msg);
    }
    obtenerProcesosClientes();
    //  obtenerClientes();

    // eslint-disable-next-line
  }, [mensaje]);

  const addEstados = (proceso) => {
    guardarPrestamoActual(proceso);
    history.push("/cliente/actuaciones");
  };

  console.log(usuario);

  return (
    <>
    {!usuario ? null : (
        <p
          style={{
            fontSize: "25px",
          }}
        >
          <strong>Bienvenido:</strong> {usuario.nombre}
        </p>
      )}
      {/* Revisar si clientes tiene contenido */}
      {procesoCliente.length === 0 ? (
        <Alert severity="error">
          <AlertTitle>Advertencia</AlertTitle>
          <strong>No hay Procesos</strong>, comunicate con Alfaro Abogados
          Asociados para mayor información.
        </Alert>
      ) : null}

      

      <div className={classes.table}>
        <MaterialTable
          title="Listado de Procesos"
          columns={[
            {
              title: "Radicado del Proceso",
              field: "codigo",
              type: "text",
              width: 200,
            },
            { title: "Fecha", field: "fecha", type: "date", width: 200 },

            {
              title: "Descripcion del proceso",
              field: "nombre_proceso",
              width: 200,
            },
          ]}
          data={procesoCliente}
          actions={[
            {
              icon: () => <VisibilityIcon style={{ color: green[500] }} />,
              iconProps: { color: "primary" },
              tooltip: "Ver Actuaciones",
              onClick: (event, rowData) => addEstados(rowData),
            },
          ]}
          options={{
            search: true,
            actionsColumnIndex: -1,
            tableLayout: "auto",

            // filtering: true
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
              labelRowsSelect: "Filas",
              labelRowsPerPage: "Filas por pagina:",
              firstAriaLabel: "Primera Pagina",
              firstTooltip: "Primera Pagina",
              previousAriaLabel: "Pagina Anterior",
              previousTooltip: "Pagina Anterior",
              nextAriaLabel: "Siguiente Pagina",
              nextTooltip: "Siguiente Pagina",
              lastAriaLabel: "Ultima Pagina",
              lastTooltip: "Ultima Pagina",
            },
            toolbar: {
              nRowsSelected: "{0} z(s) selected",
              searchPlaceholder: "Busqueda",
            },
            header: {
              actions: "Acciones",
            },
            body: {
              emptyDataSourceMessage: "Sin registros",
              addTooltip: "Agregar",
              editTooltip: "Editar",
              deleteTooltip: "Eliminar",

              editRow: {
                deleteText: "¿Está usted seguro de eliminar este cliente?",
                saveTooltip: "Guardar",
                cancelTooltip: "Cancelar",
              },

              // filterRow: {
              //     filterTooltip: 'Filter'
              // }
            },
          }}
        />
      </div>
    </>
  );
};

export default ProcesoCliente;
