import React, { useState, useContext, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AuthContext from "../../context/autenticacion/authContext";
import AlertaContext from "../../context/alerta/alertaContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { CancelScheduleSendSharp } from "@material-ui/icons";
import fondo from "../../../src/assets/img/bg6.jpg";
import Card from "@material-ui/core/Card";
import Zoom from 'react-reveal/Zoom'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.alfaroasociados.com.co" target="_blank">
        www.alfaroasociados.com.co
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginBottom: "0rem",
    paddingBottom: "2rem",
    border:'0.5rem'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    marginTop: "0rem",
    width: "auto",
    //  position: "aboslute",
  },
}));

const NuevaCuenta = (props) => {
  //extraer los valores del context
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const authContext = useContext(AuthContext);
  const { mensaje, autenticado, registrarUsuario } = authContext;

  //En caso de que el usuario se haya autenticado o registrado o sea un registro duplicado
  useEffect(() => {
    if (autenticado && usuario.tipo === "administrador") {
      props.history.push("/admin/cliente");
    } else if (autenticado && usuario.tipo === "cliente")
      props.history.push("/cliente/estadosClientes");
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
  }, [mensaje, autenticado, props.history]);

  //State para iniciar sesion
  const [usuario, guardarUsuario] = useState({
    tipo: "cliente",
    nombre: "",
    cedula: "",
    email: "",
    password: "",
    confirmar: "",
  });

  //extraer de usuario
  const { tipo, nombre, cedula, email, password, confirmar } = usuario;

  const onChange = (e) => {
    guardarUsuario({
      ...usuario,
      [e.target.name]: e.target.value,
    });
  };

  //Cuando el usuario quiere crear la cuenta
  const onSubmit = (e) => {
    e.preventDefault();

    //Validar que no halla campos vacios
    if (
      nombre.trim() === "" ||
      cedula.trim() === "" ||
      email.trim() === "" ||
      password.trim() === "" ||
      confirmar.trim() === ""
    ) {
      console.log("Todos los campos son obligatorios");
      mostrarAlerta("Todos los campos son obligatorios", "danger");
      return;
    }

    //Password minimo de 6 caracteres
    if (password.length < 6) {
      console.log("El password debe ser al menos de 6 caracteres");
      mostrarAlerta("El password debe ser de al menos 6 caracteres", "danger");
      return;
    }

    //Los 2 passwords deben ser iguales
    if (password !== confirmar) {
      console.log("Los passwords no son iguales");
      mostrarAlerta("Los passwords deben ser iguales", "danger");
      return;
    }

    //Pasarlo al action
    registrarUsuario({
      tipo,
      nombre,
      cedula,
      email,
      password,
    });
  };

  const classes = useStyles();

  return (
    <div>
    <div
      style={{
        backgroundImage: "url(" + fondo + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
        width: "auto",
        height: "120vh",
      }}
    >
      <div
        style={{
          paddingTop: "4rem",
        }}
      >
    <Zoom>
    <Container maxWidth="xs">
      <CssBaseline />
      <Card className={classes.card}>
      <div className={classes.paper}>
        {alerta ? (
          <SnackbarContent
            message={alerta.msg}
            color={alerta.categoria}
            close
          />
        ) : null}
        <Avatar className={classes.avatar}>
          <CreateOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrate Aquí
        </Typography>
        <form className={classes.form} onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            label="Tu Nombre"
            name="nombre"
            autoComplete="name"
            value={nombre}
            onChange={onChange}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            label="Cedula"
            name="cedula"
            autoComplete="cedula"
            value={cedula}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo Electronico"
            name="email"
            autoComplete="email"
            value={email}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmar"
            label="Repite tu contraseña"
            type="password"
            id="confirmar"
            value={confirmar}
            autoComplete="current-password"
            onChange={onChange}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrate
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                Volver a Iniciar Sesión
              </Link>
            </Grid>
          </Grid>
        </form>
      </div><div style={{ marginBottom: "1rem" }}>
      <Box >
        <Copyright />
      </Box>
         </div>
      
      </Card>
    </Container>
    </Zoom>
    </div>
      </div>
    </div>
  );
};

export default NuevaCuenta;
