/*eslint-disable*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Account_Box from "@material-ui/icons/AccountBoxOutlined";
import Button from "components/CustomButtons/Button.js";
//import NuevoCliente from 'componentsPrestamo/Clientes/NuevoCliente';
import BasicTable from "componentsPrestamo/Clientes/ListadoClientes";

const Contenedor = styled.div`
  margin-top: 1rem;
`;

const Cliente = () => {
  return (
    <>
      <Contenedor>
        <BasicTable />
      </Contenedor>
    </>
  );
};

export default Cliente;
