import {
    CUOTAS_PRESTAMO,
    AGREGAR_CUOTA,
    VALIDAR_CUOTA,
    ELIMINAR_CUOTA,
    ACTUALIZAR_CUOTA,
    CUOTA_ACTUAL,
    CUOTAS_TOTALES
} from '../../types/index'

export default (state, action) =>{
    switch(action.type){

            case CUOTAS_PRESTAMO:
                console.log(action.payload)
                return{
                ...state,
                cuotasPrestamo: action.payload
                }  


                case CUOTAS_TOTALES:
                console.log(action.payload)
                return{
                ...state,
                cuotas: action.payload
                }  

            case AGREGAR_CUOTA:
                return{
                ...state,
                cuotasPrestamo: [action.payload, ...state.cuotasPrestamo],
                errorcuota: false,
                agregarCuota: action.payload
                }

            case VALIDAR_CUOTA:
                return{
                ...state,
                errorcuota: true
                }

            case ELIMINAR_CUOTA:
                return{
                ...state,
                cuotasPrestamo: state.cuotasPrestamo.filter(cuota => cuota._id !== action.payload),
                }

            case CUOTA_ACTUAL:
                return{
                ...state,
                cuotaseleccionada: action.payload
                }
                
            case ACTUALIZAR_CUOTA:
                return{
                ...state,
                cuotasPrestamo: state.cuotasPrestamo.map(cuota => cuota._id === action.payload._id ? action.payload : cuota),
               cuotaseleccionada: null
                }

        default:
            return state;
    }

}