import {
  REGISTRO_EXITOSO,
  REGISTRO_ERROR,
  OBTENER_USUARIO,
  LOGIN_EXITOSO,
  LOGIN_ERROR,
  CERRAR_SESION,
  ACTUALIZAR_CONTRASEÑA,
  RESET_CONTRASEÑA,
  RESTORE_ERROR,
  RESET_ERROR,
  CONTACTENOS
} from "../../types/index";

export default (state, action) => {
  switch (action.type) {
    case REGISTRO_EXITOSO:
    case LOGIN_EXITOSO:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        autenticado: true,
        mensaje: null,
        cargando: false,
      };

    case CERRAR_SESION:
    case LOGIN_ERROR:
    case REGISTRO_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        mensaje: action.payload,
        cargando: false,
      };

    case OBTENER_USUARIO:
      return {
        ...state,
        autenticado: true,
        usuario: action.payload,
        cargando: false,
      };

    case ACTUALIZAR_CONTRASEÑA:
      return {
        ...state,
        correo: action.payload,
      };

    case RESET_CONTRASEÑA:
      return {
        ...state,
        info: action.payload,
      };

    case RESET_ERROR:
    case RESTORE_ERROR:     
      return {
        ...state,
        mensaje: action.payload,
      };

     case CONTACTENOS:{
       return {
         ...state,
         mensajeContactenos: action.payload
       }
     }

    default:
      return state;
  }
};