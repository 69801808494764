/*eslint-disable*/
import React, { useState, useContext, useEffect, Fragment } from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Fab from "@material-ui/core/Fab";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Container, TextField } from "@material-ui/core";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import clienteContext from "../../context/cliente/clienteContext";
import AlertaContext from "../../context/alerta/alertaContext";
import { useHistory } from "react-router-dom";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { green, red } from "@material-ui/core/colors";
import NumberFormat from "react-number-format";
//import customTabsStyle from 'assets/jss/material-dashboard-react/components/customTabsStyle';
import Select from "react-select";
import PrestamoContext from "../../context/prestamo/prestamoContext";
import { boxShadow } from "assets/jss/material-dashboard-react";

//import Autocomplete from '@material-ui/lab/Autocomplete';
//import MenuItem from '@material-ui/core/MenuItem';
//import InputLabel from '@material-ui/core/InputLabel';
//import Swal from 'sweetalert2';

import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  texto: {
    margin: theme.spacing(1, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //fontWeight:'bold',
    fontSize: "24px",
  },
  texto2: {
    margin: theme.spacing(0, 0, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //fontWeight:'bold',
    fontSize: "14px",
  },
  producto: {
    borderRadius: "1rem",
    borderColor: "transparent",
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
  title: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#101010",
    fontWeight: "bold",
  },
  fecha: {
    "& .MuiInputBase-root": {
      color: "black", // (default alpha is 0.38)
    },
  },
  form: {
    width: "100%",
  },
  botones: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  select: {
    marginTop: theme.spacing(2),
    height: "50px",
    backgroundColor: "white",
    marginBottom: "0px",
    fontSize:'16px',
    boxShadow:'white'
  },
  fields: {
    "&:hover fieldset": {
      borderColor: "yellow",
    },
  },
  header: {
    backgroundColor: "#2600f5c9",
  },
}));

const selectStyles = {
  menu: (base) => ({
    ...base,
    zIndex: 100,
  }),
  control: (base) => ({
    ...base,
    height: 55,
    minHeight: 35,
  }),
};

const Joyeria = () => {
  const history = useHistory();

  const classes = useStyles();

  //Obtener el state del formulario
  const prestamosContext = useContext(PrestamoContext);
  const {
    prestamos,
    agregarPrestamo,
    obtenerPrestamos,
    errorformulario,
    mostrarError,
    prestamoseleccionado,
    actualizarPrestamo,
  } = prestamosContext;

  const clientesContext = useContext(clienteContext);
  const { mensaje, clientes, obtenerClientes } = clientesContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  //State para el evento
  const [prestamo, guardarPrestamo] = useState({
    codigo: "",
    nombre_proceso: "",
    dependencia: "",
  });

  //State de cliente

  const [cliente, setCliente] = useState([]);

  //State de fecha inicio
  const [selectedDate, handleDateChange] = useState(new Date());

  const [aviso, setAviso] = useState("");
  const [tc, setTC] = useState(false);

  //Extraer valores del state de cliente

  const { codigo, nombre_proceso, dependencia } = prestamo;

  //Effect que detecta si hay un prestamo seleccionado
  console.log(prestamoseleccionado);

  useEffect(() => {
    if (prestamoseleccionado !== null) {
      guardarPrestamo(prestamoseleccionado);
      cliente_seleccionado();
      handleDateChange(new Date(prestamoseleccionado.fecha));
      console.log(prestamoseleccionado);
    } else {
      guardarPrestamo({
        codigo: "",
        nombre_proceso: "",
        dependencia: "",
      });
      obtenerPrestamos();
    }
  }, [prestamoseleccionado]);

  useEffect(() => {
    //Si hay un error
    if (mensaje) {
      
      mostrarAlerta(mensaje.msg, mensaje.categoria);
      console.log(mensaje.msg);
    }
    obtenerClientes();

    // eslint-disable-next-line
  }, [mensaje]);

  //Lee los contenidos de los inputs
  const onChange = (e) => {
    guardarPrestamo({
      ...prestamo,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitCliente = (e) => {
    e.preventDefault();

    if (
      codigo.trim() === "" ||
      nombre_proceso.trim() === "" ||
      dependencia.trim() === "" ||
      cliente.length === 0
    ) {
      setTC(true)
      setAviso("Todos los campos son obligatorios");
      return;
    }


    for(var i= 0; i <= prestamos.length-1 ; i++ ){
     
      if(prestamos[i].codigo ===  parseInt(codigo) ){
        setTC(true)
      setAviso("Codigo digitado ya existe");
      return;
      }else{
        prestamo.codigo = codigo
      }      

    }



    if (prestamoseleccionado === null) {
      prestamo.codigo = codigo;
      prestamo.nombre_proceso = nombre_proceso;
      prestamo.dependencia = dependencia;
      prestamo.cliente = cliente._id;
      prestamo.nombre_cliente = cliente.nombre_completo;
      prestamo.fecha = selectedDate;
      agregarPrestamo(prestamo);
    } else {
      //Actualizar prestamo axistente
      prestamo.codigo = codigo;
      prestamo.nombre_proceso = nombre_proceso;
      prestamo.dependencia = dependencia;
      prestamo.cliente = cliente._id;
      prestamo.nombre_cliente = cliente.nombre_completo;
      prestamo.fecha = selectedDate;
      actualizarPrestamo(prestamo);
    }

    // Reiniciar el form
    guardarPrestamo({
      codigo: "",
      nombre_proceso: "",
    });

    history.push("/admin/proceso");
    //  setTimeout(() => {
    //   window.location.reload()
    //  }, 2000);
  };

  const seleccionarCliente = (cliente) => {
    setCliente(cliente);
  };

  //Mover pantalla hacia arriba
  const mover = () => {
    const grid = document.querySelector(".grid");
    grid.scrollIntoView({ behavior: "smooth" });
  };

  const cliente_seleccionado = () => {
    const cliente_selec = prestamoseleccionado.cliente;
    const filtrar = clientes.filter((cliente) => cliente._id === cliente_selec);
    setCliente(filtrar);
  };

  const redireccionar = () => {
    obtenerPrestamos();
    history.push("/admin/proceso");
  };


 
  return (
    <>
      <div className="grid">
        <GridItem xs={12} sm={12} md={6}>
          <Fab
            color="secondary"
            onClick={() => redireccionar()}
            title="Regresar"
            style={{
              backgroundColor: "#475eff",
            }}
          >
            <ArrowBackOutlinedIcon />
          </Fab>
        </GridItem>

        <Container maxWidth="md">
          <GridContainer className={classes.title}>
            <GridItem xs={12} sm={12} md={12}>
               <Snackbar
                place="tc"
                color="danger"
                icon={AddAlert}
                message={aviso}
                open={tc}
                closeNotification={() => setTC(false)}
                close
              /> 
            </GridItem>
            <form
              className={classes.form}
              noValidate
              onSubmit={onSubmitCliente}
            >
              <Card className={classes.producto}>
                <CardHeader color="primary">
                  <h4 className={classes.texto}>Crear Proceso</h4>
                  <p className={classes.texto2}>
                    Complete los siguientes campos
                  </p>
                </CardHeader>

                <CardBody>
                  <h4 className={classes.title}>
                    Digite la Informacion del Proceso{" "}
                  </h4>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <Select
                        isDisabled={false}
                        styles={selectStyles}
                        className={classes.select}
                        options={clientes}
                        onChange={(opcion) => seleccionarCliente(opcion)}
                        getOptionValue={(opciones) => opciones.id}
                        getOptionLabel={(opciones) => opciones.nombre_completo}
                        placeholder="Busque o Seleccione el Cliente"
                        noOptionsMessage={() => "No hay resultados "}
                        isSearchable="true"
                        value={cliente}
                        autoFocus
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={esLocale}
                      >
                        <DatePicker
                          inputVariant="outlined"
                          label="Fecha de Inicio"
                          margin="normal"
                          fullWidth
                          name="fecha_inicio"
                          id="fecha_inicio"
                          format="dd/MM/yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                        />
                      </MuiPickersUtilsProvider>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="codigo"
                        label="Radicado del Proceso"
                        name="codigo"
                        autoComplete="codigo"
                        value={codigo}
                        onChange={onChange}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="dependencia"
                        label="Dependencia"
                        name="dependencia"
                        autoComplete="dependencia"
                        value={dependencia}
                        onChange={onChange}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        type="number"
                        fullWidth
                        id="nombre_proceso"
                        label="Nombre del Proceso"
                        name="nombre_proceso"
                        rows={3}
                        multiline={true}
                        value={nombre_proceso}
                        autoComplete="nombre_proceso"
                        onChange={onChange}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.botones}>
                      <Button
                        size="small"
                        variant="contained"
                        color="danger"
                        onClick={() => history.push("/admin/proceso")}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <div className={classes.botones}>
                      <Button
                        size="small"
                        type="submit"
                        variant="contained"
                        color="info"
                        style={{
                          backgroundColor: "#475eff",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                        }}
                        onClick={() => mover()}
                      >
                        {prestamoseleccionado
                          ? "Editar Proceso"
                          : "Registrar Proceso"}
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </Card>
            </form>
          </GridContainer>
        </Container>
      </div>
    </>
  );
};

export default Joyeria;
