import React, {useContext, useEffect, useState, Fragment} from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import MaterialTable from 'material-table'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import Swal from 'sweetalert2';
import PrestamoContext from '../../context/prestamo/prestamoContext'

import CuotaContext from '../../context/cuota/cuotaContext'


const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor:'red'
    },

    table:{
        paddingTop:'2rem'
    } ,
    producto:{
        borderRadius: "1rem",
        borderColor: "transparent",
        boxShadow: '0 14px 28pxrgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        width:'1000px',
        display: 'flex',
        left:'10%'
     
    },
    title: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color:'red',
        fontWeight:'bold'
     },
     contenedor:{
        backgroundColor:'#EAECEE',
        borderTop:'1px solid #5499C7',
        borderBottom:'1.5px solid #5499C7',
        borderRight: '1.5px solid #5499C7',
        borderLeft:'4px solid red',
        borderRadius:'10px',
        paddingTop:'2rem',
        paddingLeft:'2rem',
        paddingRight:'2rem',
        paddingBottom:'4rem',
        height:'auto',
        marginBottom:'2rem',
        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
     },

     contenedorInterno:{
         marginLeft:'2rem',
         paddingBottom:'2rem'
     },
     numero:{
      color:'red',
      fontWeight:'bold'
     }
  }));



const ListadoCuotas = () => {
    const classes = useStyles();

    const prestamosContext = useContext(PrestamoContext)
    const {prestamoseleccionado} = prestamosContext;

    const CuotasContext = useContext(CuotaContext);
    const{cuotasPrestamo, obtenerCuotas, eliminarCuota, guardarCuotaActual } = CuotasContext
    
useEffect(() => {
  if(prestamoseleccionado !== null){
    obtenerCuotas(prestamoseleccionado._id)
  }
 
}, [prestamoseleccionado])
    
   if(!prestamoseleccionado) return null

 
 //  obtenerCuotas(prestamoseleccionado._id)
  
//Eliminar la cuota seleccionada
  const onClickEliminar = id =>{
 //   console.log(id)
    eliminarCuota(id, prestamoseleccionado._id);
     
  }
 

  //Agregar una cuota actual cuando el usuario desee editarlo
 const seleccionarCuota = cuota =>{
  guardarCuotaActual(cuota);

}

    return ( 

       
        <>
        
                 <div className={classes.table}>
               <MaterialTable
               title="Listado de Cuotas"
               columns={[
                        { title: 'Fecha', 
                        field: 'fecha',
                        defaultSort:'desc',
                        type:'date',
                        },

                      { title: 'Valor Cuota', 
                        field: 'valor_cuota',
                        type:'currency',
                      },
                      { title: 'Tipo de Cuota', 
                       field: 'tipo_cuota',
                      },
                     
                     { title: 'Observaciones', 
                       field: 'observaciones',
                      
                      },
                
               ]}
              
               data={cuotasPrestamo} 
               actions={[
                   {
                   icon: ()=> <EditOutlinedIcon color="primary" />,
                   iconProps: {color: 'primary'},                  
                   tooltip: 'Editar',
                   onClick: (event, rowData) =>seleccionarCuota(rowData)
                   },
                  
                   {
                   icon: () => <DeleteOutlineOutlinedIcon color="secondary" />,
                   iconProps: {color: 'secondary'},  
                   tooltip: 'Eliminar',   
                   onClick: (event, rowData) =>
                   Swal.fire({
                     title: '¿Deseas eliminar esta cuota?',
                     text: "Esta accion no se puede deshacer",
                     icon: 'warning',
                     showCancelButton: true,
                     confirmButtonColor: '#3085d6',
                     cancelButtonColor: '#d33',
                     confirmButtonText: 'Si, Eiminar',
                     cancelButtonText: 'No, Cancelar'
                   }).then( async (result) => {
                     if (result.value) {
                        try {
         
                        onClickEliminar(rowData._id)
                        
         
                         Swal.fire(
                           'Eliminado',
                           '' ,
                           'success'
                         )
                         
                        } catch (error) {
                          console.log(error)
                        }
                         
                       
                     }
                    
                    
                   })
                   
                   }
               ]}
               options={{
                 search:true,
                 actionsColumnIndex: -1,
                 tableLayout:'auto',
                 sorting: true
                 
                // filtering: true                
               
               }}
 
               localization={{
                 pagination: {
                     labelDisplayedRows: '{from}-{to} de {count}',
                     labelRowsSelect:'Filas',
                     labelRowsPerPage:'Filas por pagina:',
                     firstAriaLabel:'Primera Pagina',
                     firstTooltip:'Primera Pagina',
                     previousAriaLabel:'Pagina Anterior',
                     previousTooltip:'Pagina Anterior',
                     nextAriaLabel:'Siguiente Pagina',
                     nextTooltip:'Siguiente Pagina',
                     lastAriaLabel:'Ultima Pagina',
                     lastTooltip:'Ultima Pagina'
                 },
                 toolbar: {
                     nRowsSelected: '{0} z(s) selected',
                     searchPlaceholder: 'Busqueda' 
 
                 },
                 header: {
                     actions: 'Acciones'
                 },
                 body:                 
                 {
                     emptyDataSourceMessage: 'Sin registros',
                     addTooltip:'Agregar',
                     editTooltip:'Editar',
                     deleteTooltip:'Eliminar',
                     
                     
                     editRow:{
                     deleteText:'¿Está usted seguro de eliminar este cliente?',
                     saveTooltip:'Guardar',
                     cancelTooltip:'Cancelar',
                     
                 }
                   
                     // filterRow: {
                     //     filterTooltip: 'Filter'
                     // }
                     
                 }
                 
             }}
              
               />
               </div>
 
 </>
 

     );
}
 
export default ListadoCuotas;