import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/autenticacion/authContext";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import logo from "../../assets/img/bg5.jpg";
import Container from "@material-ui/core/Container";
import AlertaContext from "../../context/alerta/alertaContext";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({}));

const ResetPassword = () => {
  const history = useHistory();
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const { resetPassword, info, mensaje } = authContext;

  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  useEffect(() => {
    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
    // eslint-disable-next-line
  }, [mensaje]);

  const [data, setData] = useState({
    newPass: "",
    confirmar: "",
  });

  const { newPass, confirmar } = data;

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    //Validar que no halla campos vacios
    if (newPass.trim() === "" || confirmar.trim() === "") {
      console.log("Los campos son obligatorios");
      mostrarAlerta("Los campos son obligatorios", "danger");
      return;
    }

    //Password minimo de 6 caracteres
    if (newPass.length < 6) {
      console.log("El password debe ser al menos de 6 caracteres");
      mostrarAlerta("El password debe ser de al menos 6 caracteres", "danger");
      return;
    }

    //Los 2 passwords deben ser iguales
    if (newPass !== confirmar) {
      console.log("Los passwords no son iguales");
      mostrarAlerta("Los passwords deben ser iguales", "danger");
      return;
    }

    const link = window.location.href;
    const resetLink = link.substr(52);
    // console.log(resetLink);

    //Pasarlo al action
    resetPassword({
      resetLink,
      newPass,
    });
  };

  if (info) {
    setTimeout(() => {
      history.push("/");
    }, 6000);
  }

  return (
    <>
      <GridContainer>
        <img
          style={{
            maxHeight: "auto",
            maxWidth: "auto",
            height: "250px",
            width: "1400px",
            boxShadow: "0px 10px 15px 0px rgba(87,25,25,1)",
          }}
          src={logo}
        />

        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            {alerta ? (
              <SnackbarContent
                classes={classes.alerta}
                message={alerta.msg}
                color={alerta.categoria}
                close
              />
            ) : null}
            {info ? (
              <SnackbarContent
                style={{
                  alignContent: "center",
                }}
                message={info}
                color="success"
                close
              />
            ) : null}

            <p
              style={{
                paddingTop: "2rem",
                lineHeight: "0em",
                fontSize: "calc(1.5em + 1vw)",
              }}
            >
              <strong>Alfaro Abogados Asociados</strong>
            </p>
            <p
              style={{
                paddingTop: "0rem",
                lineHeight: "0em",
                fontSize: "calc(0.5em + 1vw)",
              }}
            >
              Bienvenido(a) a nuestro sistema para restaurar contraseñas{" "}
            </p>

            <p
              style={{
                paddingTop: "0rem",
                lineHeight: "0em",
                fontSize: "calc(0.5em + 1vw)",
              }}
            >
              Digite su nueva contraseña
            </p>
          </div>
        </GridItem>

        <Container
          maxWidth="lg"
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <form onSubmit={onSubmit} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPass"
              label="Contraseña"
              type="password"
              id="newPass"
              autoComplete="current-password"
              value={newPass}
              onChange={onChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmar"
              label="Repite tu contraseña"
              type="password"
              id="confirmar"
              value={confirmar}
              autoComplete="current-password"
              onChange={onChange}
            />
            <div
              style={{
                paddingTop: "1rem",
                paddingLeft: "6rem",
                paddingBottom: "2rem",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
              >
                Restaurar Contraseña
              </Button>
            </div>
          </form>
        </Container>
      </GridContainer>
    </>
  );
};

export default ResetPassword;
