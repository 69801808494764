/*eslint-disable*/
import React, { useContext } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AuthContext from "../../context/autenticacion/authContext";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();

  //Extraer la informacion de autenticacion
  const authContext = useContext(AuthContext);
  const { usuario, usuarioAutenticado, cerrarSesion } = authContext;

  const cerrarSession = () => {
    cerrarSesion();
  };

  return (
    <div>
      <div style={{ marginRight:'2rem'}} className={classes.searchWrapper}>
        <Button color="danger" aria-label="edit" onClick={() => cerrarSesion()}>
          <ExitToAppIcon /> Cerrar Sesion
        </Button>
      </div>
    </div>
  );
}
